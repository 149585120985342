import {IFieldValidator} from "../field-validator.interface";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {NullableString} from "../../../types/nullable-types";
import {phoneNumberParse} from "../../../components/forms/contact/phone-number-helpers";

export class PhoneNumberValidator implements IFieldValidator {
    constructor(private readonly services: IServiceFactory) {
    }

    validate(fieldValue: any): NullableString {
        if(!fieldValue) {
            return null;
        }

        const phoneNumber = phoneNumberParse(fieldValue.toString());

        if(phoneNumber?.isValid()) {
            return null;
        }

        return this.services.language.translate('Is not a valid phone number');
    }
}