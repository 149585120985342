import {IApplicationService} from "./application.service.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import {App} from "@capacitor/app";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {AppVersionUpgradeModeEnum} from "../airline-webapi/responses/get-configurations.response";
//import {registerPlugin} from '@capacitor/core';
//import {compare} from 'compare-versions';
/*import {
    AppTrackingTransparency,
    AppTrackingStatusResponse,
  } from 'capacitor-plugin-app-tracking-transparency';

 */
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

//https://developer.android.com/guide/playcore/in-app-updates

export class ApplicationService extends ServiceBase implements IApplicationService {



    constructor(services: IServiceFactory) {
        super(services);

        this._cookiesPolicyAccepted = this.services.localStorage.getItem('app.cookiesPolicyAccepted') === 'true';
        makeObservable(this, {
            isActive: observable.ref,
            _cookiesPolicyAccepted: observable.ref
        });

        App.addListener('pause', async () => {
            runInAction(() => {
                this.isActive = false;
            });
        });

        App.addListener('resume', async () => {
            runInAction(() => {
                this.isActive = true;
            });
        });

        reaction(() => this.services.configuration.appVersionUpgradeMode,
            async (appVersionBlockingMode) => {
                if (appVersionBlockingMode === AppVersionUpgradeModeEnum.Mandatory || appVersionBlockingMode === AppVersionUpgradeModeEnum.Optional) {
                    await this.showAppVersionUpdateSuggestionDialog(appVersionBlockingMode);
                }
            }, {
                fireImmediately: true
            });
    }

    isActive = true;

    _cookiesPolicyAccepted: boolean;
    get cookiesPolicyAccepted(): boolean {
        return this._cookiesPolicyAccepted;
    }

    async showCookiesPolicy(): Promise<void> {

        // TODO: we need to figure what to do with this
        if(this.services.configuration.isAnalyticsEnabled ) {
            if (this.services.device.isIOS) {
                /*
                const response = await this.requestTrackingPermission();
                if (response.status === "denied") {
                    FirebaseAnalytics.setCollectionEnabled({enabled: false});
                }
                 */
            }
        } else {
            //FirebaseAnalytics.setCollectionEnabled({enabled: false});
        }

        if (!this.cookiesPolicyAccepted) {
            await this.services.dialogFactory.showCookiesPolicy();
        }
    }

    set cookiesPolicyAccepted(value: boolean) {
        if (value) {
            this.services.localStorage.setItem('app.cookiesPolicyAccepted', 'true');
        } else {
            this.services.localStorage.removeItem('app.cookiesPolicyAccepted');
        }

        runInAction(() => {
            this._cookiesPolicyAccepted = value;
        })
    }

    async showAppVersionUpdateSuggestionDialog(appVersionUpgradeMode: AppVersionUpgradeModeEnum): Promise<void> {
        await this.services.dialogFactory.showAppVersionUpdateSuggestion({
            appVersionUpgradeMode: appVersionUpgradeMode
        });
    }


/*
    public async requestTrackingPermission(): Promise<AppTrackingStatusResponse> {
        return await AppTrackingTransparency.requestPermission();
    }

 */
}
