import React from 'react';
import {IPaymentMethodTabViewModel} from "./payment-method-tab.view-model.interface";
import {PaymentMethodTabEnum} from "./payment-method-tab.enum";
import {BookingModel} from "../../booking.model";
import {PaymentHandlerModel} from "../payment-handler.model";


export abstract class PaymentMethodTabModel implements IPaymentMethodTabViewModel {
    constructor(public readonly tabType: PaymentMethodTabEnum,
                protected readonly booking: BookingModel) {
    }

    abstract isAvailable(): Promise<boolean>;

    abstract renderTabHeader(): React.ReactElement;
    abstract renderTabContent(): React.ReactElement;
    abstract renderPayButton(): React.ReactElement;


    protected get paymentHandler(): PaymentHandlerModel {
        return this.booking.paymentHandler;
    }
}

