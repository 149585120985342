import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {LogoApplePayTabHeaderIcon} from "../../../../assets/svg/logo-apple-pay-tab-header.icon";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
    
`

export const ApplePayPaymentMethodTabHeaderComponent: React.FC = observer(() => {
    const screenMediaQuery = useContainerMediaQueriesChecks();
    const renderLabel = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <span>
                Apple Pay
            </span>
        );
    }
    return (
        <HeaderBox>
            <LogoApplePayTabHeaderIcon/>
            {renderLabel()}
        </HeaderBox>
    )
})