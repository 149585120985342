import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {
    IApplePayMethodViewModel
} from "../../../../services/booking/models/payment/methods/apple-pay/apple-pay-method.view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {LogoApplePayIcon} from "../../../../assets/svg/logo-apple-pay.icon";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";

const ButtonBox = styled.button`
    background-color: black;
    color: white;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    
    width: fit-content;
    min-height: 30px;
    min-width: 160px;
    padding: 18px 36px;

    border: none;
    border-radius: ${props => props.theme.border.defaultRadius};
    outline: none;
    &:focus {
        outline: none;
    }
    
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: ${props => props.theme.fontWeight.medium};
    
    cursor: pointer;
    overflow: hidden;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    
`

const ButtonLabelBox = styled.span`
    white-space: nowrap;
`


const ApplePayIconBox = styled.div`
   
`

interface ApplePayButtonComponentProps extends PropsWithChildren {
    applePayMethod: IApplePayMethodViewModel;
    className?: string;
}

export const ApplePayButtonComponent: React.FC<ApplePayButtonComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const onClickHandler = async () => {
        await props.applePayMethod.pay();
    }

    const renderLabel = () => {

        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <ButtonLabelBox>
                {services.language.translate('Book with')}
            </ButtonLabelBox>
        )

    }

    return (
        <ButtonBox className={props.className}
                   aria-label={services.language.translate('Book with Apple Pay')}
                   onClick={onClickHandler}>

            {renderLabel()}
            <ApplePayIconBox>
                <LogoApplePayIcon/>
            </ApplePayIconBox>

        </ButtonBox>
    )
})