import {ILoadScriptOptions, IScriptLoaderService} from "./script-loader.service.interface";

export class ScriptLoaderService implements IScriptLoaderService {

    private _loadedScripts: Record<string, Promise<void> | undefined> = {};
    loadScript(options: ILoadScriptOptions): Promise<void> {
        let promise = this._loadedScripts[options.url.toLowerCase()];
        if(promise) {
            return promise;
        }

        promise = new Promise<void>((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = options.url;
            scriptElement.onload = () => {
                resolve();
            }

            scriptElement.onerror = (err: any) => {
                reject(err);
            }

            document.head.appendChild(scriptElement);
        })

        this._loadedScripts[options.url.toLowerCase()] = promise;
        return promise;
    }

}