import {BookingModel} from "./booking.model";
import {IServiceFactory} from "../../service-factory.interface";
import {AirlineWebapiErrorCodesEnum} from "../../airline-webapi/airline-webapi-error-codes.enum";
import {NullableString} from "../../../types/nullable-types";
import {IBookingErrorHandlingViewModel} from "./booking-error-handling.view-model.interface";


export class BookingErrorHandlingModel implements IBookingErrorHandlingViewModel {
    constructor(private readonly booking: BookingModel) {
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private async _refreshBookingData(): Promise<void> {
        const bookingData = await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
        this.booking.updateBookingSessionData(bookingData);
    }

    canHandleApiError(apiErrorCode: AirlineWebapiErrorCodesEnum): boolean {
        return Boolean(this.getApiErrorMessage(apiErrorCode));
    }

    getGenericPaymentError(): string {
        return this.services.language.translate('There was an error processing your payment.');
    }

    getApiErrorMessage(apiErrorCode: AirlineWebapiErrorCodesEnum): NullableString {
        switch (apiErrorCode) {
            case AirlineWebapiErrorCodesEnum.NskServerClassNotAvailable:
                return this.services.language.translate('Unfortunately this ticket was already taken by another customer. Please start over and choose a different flight');
            default:
                return "";
        }
    }

    async handleApiError(apiErrorCode: AirlineWebapiErrorCodesEnum): Promise<void> {
        switch (apiErrorCode) {
            case AirlineWebapiErrorCodesEnum.NskServerClassNotAvailable:
                await this._handleNskServerClassNotAvailable();
                break;
            default:
                throw new Error(`Can't handle API error code ${apiErrorCode}`);
        }
    }

    private async _handleNskServerClassNotAvailable(): Promise<void> {
        await this.booking.bookingStrategyAdapter.goBackToFlightSelection();
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._refreshBookingData();
                await this.booking.flightSearchController.applySearch();
            }
        });

        const msg = this.getApiErrorMessage(AirlineWebapiErrorCodesEnum.NskServerClassNotAvailable);
        if(msg) {
            this.services.alert.showError(msg);
        }

    }
}