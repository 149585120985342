import {BookingModel} from "../../booking.model";
import {PaymentMethodCodesEnum, PaymentMethodsSortOrder} from "./payment-method-codes.enum";
import React from "react";
import {IServiceFactory} from "../../../../service-factory.interface";
import {PaymentMethodCategoryEnum} from "./payment-method-category.enum";
import {IPaymentMethodViewModel} from "./payment-method.view-model.interface";

export abstract class PaymentMethodBaseModel implements IPaymentMethodViewModel {
    constructor(protected readonly booking: BookingModel,
                public readonly code: PaymentMethodCodesEnum) {
    }

    abstract get name(): string;
    abstract renderIcon(): React.ReactElement | null;
    abstract get category(): PaymentMethodCategoryEnum;
    abstract canMakePayments(): Promise<boolean>;

    protected get services(): IServiceFactory {
        return this.booking.services;
    }


    get isSelected(): boolean {
        return this.booking.paymentHandler.isPaymentMethodSelected(this.code);
    }

    set isSelected(value: boolean) {
        if(value) {
            this.booking.paymentHandler.selectPaymentMethod(this.code);
        } else {
            this.booking.paymentHandler.unselectPaymentMethod(this.code);
        }
    }

    get sortOrder(): number {
        return PaymentMethodsSortOrder[this.code] || 99999;
    }


}
