import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    FinalPriceTermsAndConditionsToggleComponent
} from "../final-price/final-price-terms-and-conditions-toggle.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {useServices} from "../../../hooks/use-services.hook";

const FooterContentBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: ${props => props.theme.spacing.flexGap16};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        flex-direction: column;
    }
`


const PayButtonWrapperBox = styled.div`
    min-width: 180px;
`



export const PaymentPageFooterComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const selectionHandler = services.booking.current.paymentHandler.paymentMethodsTabs;

    const renderPayButton = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <PayButtonWrapperBox>
                {selectionHandler.renderPayButton()}
            </PayButtonWrapperBox>
        )
    }

    return (
        <FooterContentBox>
            <FinalPriceTermsAndConditionsToggleComponent/>
            {renderPayButton()}
        </FooterContentBox>
    )
})