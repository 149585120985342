import {ICountryConfiguration} from "./objects/country-configuration.interface";
import {IStationConfiguration} from "./objects/stations-configurations.interface";
import {ITranslationsConfigurations} from "./objects/translations-configurations.interface";
import {ICurrencyConfiguration} from "./objects/currency-configuration.interface";
import {IMarketingConfiguration} from "./objects/marketing-configuration.interface";
import {ISsrsRawConfiguration} from "./objects/ssrs/ssrs-raw-configuration.interface";
import {IBlueBenefitsConfiguration} from "./objects/blue-benefits-configuration.interface";
import {
    IMoveFlightConfiguration,
} from "./objects/move-flights-configuration.interface";

import {IInsuranceConfiguration} from "./objects/insurance-configuration.interface";
import {IItalianRegionConfiguration} from "./objects/italian-region-configuration.interface";
import {IMacConfiguration} from "./objects/mac-configuration.interface";
import {ICarrierConfiguration} from "./objects/carrier-configuration.interface";
import {ICmsConfiguration} from "./objects/cms-configuration.interface";
import {IPsoMarketsConfiguration} from "./objects/pso-markets-configuration.interface";
import {IPaymentsConfiguration} from "./objects/payments-configuration.interface";
import {IAnalyticsConfiguration} from "./objects/analytics-configuration.interface";
import {IBundleConfiguration} from "./objects/bundle-configuration.interface";
import {IFeeConfiguration} from "./objects/fee-configuration.interface";
import {IPspConfiguration} from "./objects/psp-configuration.interface";

export enum PaymentStrategyEnum {
    Webapi = 2
}

export enum ApplicationEnvironmentTypeEnum
{
    Production = 0,
    Test = 1,
    Development = 2,
    Stage = 3
}

export interface IConfigurationObject {
    readonly airlineIataCode: string;
    readonly websiteHomeUrl: string;
    readonly dotRezApiUrl: string;
    readonly dotRezApiSubscriptionKey: string;
    readonly dotRezSessionTimeout: string;
    readonly refreshConfigsInterval: string;
    readonly noBundleEarliestCheckIn: string;
    readonly countries: ICountryConfiguration[];
    readonly maxAllowedPassengersNumberOnBooking: number;
    readonly maximumBagsQuantityPerPassenger: number;
    readonly stations: IStationConfiguration[];
    readonly macs: IMacConfiguration[];
    readonly carriers: ICarrierConfiguration[];
    readonly specialPriceMarketsV2: IPsoMarketsConfiguration;
    readonly translations: ITranslationsConfigurations;
    readonly supportedCurrencies: ICurrencyConfiguration[];
    readonly marketing: IMarketingConfiguration;
    readonly ssrsConfig: ISsrsRawConfiguration;
    readonly italianRegions: IItalianRegionConfiguration[];
    readonly forbiddenVouchers: string[];
    readonly enforceLoginOnBookingFlow: boolean;
    readonly blueBenefits: IBlueBenefitsConfiguration;
    readonly enable10KgBag: boolean;
    readonly enable32KgBag: boolean;
    readonly enableEckn: boolean;
    readonly analytics: IAnalyticsConfiguration;
    readonly enableRandomSeatAllocation: boolean;
    readonly systemCodesBlockedForFlightChange: string[];
    readonly minTimeToChangeDateBeforeFlight: string;
    readonly moveFlight: IMoveFlightConfiguration;
    readonly payments: IPaymentsConfiguration;
    readonly insurance: IInsuranceConfiguration;
    readonly virtualSsrsInBundle: Record<string, string[]>;
    readonly callCenterPhoneNumber: string;
    readonly companyAddress: string;
    readonly vouchersRestrictedByPromotions: boolean;
    readonly cms: ICmsConfiguration;
    readonly bundles: Record<string, IBundleConfiguration>;
    readonly fees: IFeeConfiguration[];
    readonly pspConfigs: IPspConfiguration;

    readonly appDownloadLinks: {
        readonly ios: string;
        readonly android: string;
    }
}
