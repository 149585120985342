import {IPaymentMethodTabViewModel} from "../payment-method-tab.view-model.interface";
import {PaymentMethodTabEnum} from "../payment-method-tab.enum";
import React from "react";

export class VoidTabModel implements IPaymentMethodTabViewModel {
    readonly tabType: PaymentMethodTabEnum = PaymentMethodTabEnum.None;

    async isAvailable(): Promise<boolean> {
        return true;
    }

    renderPayButton(): React.ReactElement {
        return (
            <></>
        )
    }

    renderTabContent(): React.ReactElement {
        return (
            <></>
        )
    }

    renderTabHeader(): React.ReactElement {
        return (
            <></>
        )
    }

}