import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString, NullableUndefinedString} from "../../../types/nullable-types";
import parsePhoneNumber, {PhoneNumber} from 'libphonenumber-js';
import {CountryData} from "react-phone-input-2";


export function setPhoneFieldsValues(phoneField: IFormField<NullableString>, countryField: IFormField<NullableString>, value: string, countryData: CountryData) {
    if(value) {
        if(!value.startsWith('+')) {
            value = '+' + value;
        }
    }
    phoneField.setValue(value);
    countryField.setValue(countryData?.countryCode?.toUpperCase() ?? null);
}

export function phoneNumberParse(phoneNumberValue: NullableUndefinedString): PhoneNumber | undefined{
    if(!phoneNumberValue) {
        return undefined;
    }

    if(!phoneNumberValue.startsWith('+')) {
        phoneNumberValue = '+' + phoneNumberValue;
    }

    return parsePhoneNumber(phoneNumberValue);
}

export function getPhoneNumberCountry(phoneNumberValue: NullableUndefinedString): NullableString {
    if(!phoneNumberValue) {
        return null;
    }

    const phoneNumber = phoneNumberParse(phoneNumberValue);
    if(!phoneNumber) {
        return null;
    }

    if (phoneNumber.isValid() && phoneNumber.country) {
        return phoneNumber.country;
    }

    return null;
}

export function setInitialPhoneCountry(phoneField: IFormField<NullableString>, countryField: IFormField<NullableString>): void {
    if(!phoneField.value) {
        return;
    }

    if(countryField.value) {
        return;
    }


    const country = getPhoneNumberCountry(phoneField.value.toString());
    if(country) {
        countryField.setValue(country);
    }
}

