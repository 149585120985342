import React from "react";
import {observer} from "mobx-react";
import {HomePageGreetingMessageComponent} from "./greeting-message/home-page-greeting-message.component";
import styled from "styled-components";
import {
    SearchMaskWebappLageScreenComponent
} from "../../../components/search-mask/webapp/large-screen/search-mask.webapp.lage-screen.component";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {
    StandardPageHeaderLargeScreenComponent
} from "../../../basic-components/page/standard-page-header/standard-page-header.large-screen.component";
import {
    HomePageStandardContentWebappComponent
} from "./boxes/home-page-content-wrapper.webapp.box";
import {CopyRightComponent} from "../../../components/airline-company-info/copy-right.component";



const HomePageContentWrapperWebappBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding-top: ${props => props.theme.spacing.spacing48};
`

const GreetingAndSearchMaskContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 98%;
    gap: ${props => props.theme.spacing.spacing16};
    margin-bottom: 5%;
`


export const HomepageWebappLargeScreenComponent: React.FC = observer(() => {

    return (
        <RoutedPageComponent disableSwipeNavigation={true}>
            <StandardPageHeaderLargeScreenComponent hideBackButton={false}/>
            <HomePageStandardContentWebappComponent>
                <HomePageContentWrapperWebappBox>
                    <GreetingAndSearchMaskContainerBox>
                        <HomePageGreetingMessageComponent/>
                        <SearchMaskWebappLageScreenComponent/>
                    </GreetingAndSearchMaskContainerBox>
                    <CopyRightComponent/>
                </HomePageContentWrapperWebappBox>

            </HomePageStandardContentWebappComponent>
        </RoutedPageComponent>
    );
})