import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {
    ICreditCardPaymentHandlerViewModel
} from "../../../../services/booking/models/payment/tabs/credit-card/credit-card-payment-handler.view-model";

const PayButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`


const PayButton = styled(PrimaryButton)`
    font-size: ${props => props.theme.fontSize.h4};
    gap: ${props => props.theme.spacing.spacing12};
    white-space: nowrap;
`

export const CreditCardPayButtonComponent: React.FC<{paymentHandler: ICreditCardPaymentHandlerViewModel}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const onPayButtonClick = async () => {
        await props.paymentHandler.startPayment();
    }

    const selectedPaymentMethod = booking.paymentHandler.creditCardPaymentMethods.find(pm => pm.isSelected);

    return  (<PayButtonContainer>
        <PayButton onClick={onPayButtonClick} fullWidth={true}>
            {selectedPaymentMethod && selectedPaymentMethod.renderIcon({forPayButton: true})}
            <span>
                {services.language.translate('Pay Now')}
            </span>

        </PayButton>
    </PayButtonContainer>)
})