import {makeObservable, observable, runInAction} from "mobx";
import {CreditCardTabModel} from "./credit-card/credit-card-tab.model";
import {PaypalTabModel} from "./paypal/paypal-tab.model";
import {IPaymentMethodsTabsViewModel} from "./payment-methods-tabs.view-model.interface";
import {IPaymentMethodTabViewModel} from "./payment-method-tab.view-model.interface";
import React from "react";
import {
    SaveBookingButtonComponent
} from "../../../../../pages/booking-flow/payment/save-booking-button/save-booking-button.component";
import {BookingSessionStorageKeys} from "../../storage/booking-storage.interface";
import {ApplePayTabModel} from "./apple-pay/apple-pay-tab.model";
import {GooglePayTabModel} from "./google-pay/google-pay-tab.model";
import {VoidTabModel} from "./void/void-tab.model";
import {BookingModel} from "../../booking.model";

export class PaymentMethodsTabsModel implements IPaymentMethodsTabsViewModel {
    constructor(private readonly booking: BookingModel) {

        this.tabs = [];
        makeObservable<this, '_selectedTab'>(this, {
            _selectedTab: observable.ref,
            tabs: observable.ref
        });
    }

    public tabs: IPaymentMethodTabViewModel[] = [];

    private _selectedTab: IPaymentMethodTabViewModel = new VoidTabModel();

    private getAllTabs(): IPaymentMethodTabViewModel[] {
        return [
            new ApplePayTabModel(this.booking),
            new GooglePayTabModel(this.booking),
            new CreditCardTabModel(this.booking),
            new PaypalTabModel(this.booking)
        ]
    }

    async loadTabs(): Promise<void> {
        const finalTabs: IPaymentMethodTabViewModel[] = [];

        for(let tab of this.getAllTabs()) {
            try {
                if(await tab.isAvailable()) {
                    finalTabs.push(tab);
                }
            } catch (err) {
                this.booking.services.logger.error(`Failed to load payment tab ${tab.tabType}`, err);
            }
        }

        runInAction(() => {
            this.tabs = finalTabs;
        });

        this._setInitialTab();
    }

    private _setInitialTab(): void {
        const storedTabType = this.booking.storage.getItem(BookingSessionStorageKeys.selectedPaymentTab);
        if (storedTabType) {
            runInAction(() => {
                this._selectedTab = this.tabs.find(g => g.tabType === parseInt(storedTabType))
                    ?? this.tabs[0]
                    ?? new VoidTabModel();
            })
        } else {
            runInAction(() => {
                this._selectedTab = this.tabs[0] ?? new VoidTabModel();
            })
        }
    }
    get selectedTab(): IPaymentMethodTabViewModel {
        return this._selectedTab;
    }

    set selectedTab(value: IPaymentMethodTabViewModel) {
        runInAction(() => {
            this._selectedTab = value;
            this.booking.storage.setItem(BookingSessionStorageKeys.selectedPaymentTab, value.tabType.toString())

        });
    }

    renderPayButton(): React.ReactElement {
        if (this.booking.balanceDue.amount < 0) {
            return (
                <></>
            );
        }
        if (this.booking.balanceDue.amount === 0) {
            return (
                <SaveBookingButtonComponent/>
            )
        }

        return this.selectedTab.renderPayButton();
    }
}