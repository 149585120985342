import {PaymentMethodTabModel} from "../payment-method-tab.model";
import React from "react";
import {
    ApplePayPaymentMethodTabHeaderComponent
} from "../../../../../../pages/booking-flow/payment/apple-pay/apple-pay-payment-method-tab-header.component";
import {
    ApplePayPaymentMethodTabContentComponent
} from "../../../../../../pages/booking-flow/payment/apple-pay/apple-pay-payment-method-tab-content.component";
import {
    ApplePayButtonComponent
} from "../../../../../../pages/booking-flow/payment/apple-pay/apple-pay-button.component";
import {PaymentMethodTabEnum} from "../payment-method-tab.enum";
import {IApplePayMethodViewModel} from "../../methods/apple-pay/apple-pay-method.view-model.interface";
import {BookingModel} from "../../../booking.model";
export class ApplePayTabModel extends PaymentMethodTabModel {
    constructor(booking: BookingModel) {
        super(PaymentMethodTabEnum.ApplePay, booking);
    }

    private get applePayMethod(): IApplePayMethodViewModel | null {
        return this.paymentHandler.applePayMethod;
    }

    async isAvailable(): Promise<boolean> {

        if(this.applePayMethod) {
            return await this.applePayMethod.canMakePayments();
        }

        return false;
    }


    renderTabHeader(): React.ReactElement {
        return (
            <ApplePayPaymentMethodTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <ApplePayPaymentMethodTabContentComponent/>
        );
    }

    renderPayButton(): React.ReactElement {
        if(!this.applePayMethod) {
            return (
                <></>
            )
        }
        return (
            <ApplePayButtonComponent applePayMethod={this.applePayMethod}/>
        );
    }

}