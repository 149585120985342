import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {ManageMyBookingContactDialogComponent} from "./manage-my-booking-contact-dialog.component";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ManageMyBookingEditSectionButtonComponent} from "../edit-button/manage-my-booking-edit-section-button.component";
import {ManageMyBookingSectionTitleBox} from "../../boxes/manage-my-booking-section-title.box";
import {ReadonlyFieldComponent} from "../../../../../components/forms/readonly-field/readonly-field.component";
import {PhoneNumberInputComponent} from "../../../../../components/forms/contact/phone-number-input.component";

const SectionContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
`


const SectionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const BookingContactFieldsBox = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        grid-template-columns: 1fr;
    }
    
`



export const ManageMyBookingContactComponent: React.FC = observer(() => {
    const services = useServices();

    const onClickHandler = async () => {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: dialogHandler => (<ManageMyBookingContactDialogComponent dialogHandler={dialogHandler}/>),
            onAccept: async () => {
                await services.booking.current.contact.save();
            },
            onReject: async () => {
                services.booking.current.contact.cancelChanges();
            }
        });
    }


    const contactDetails = services.booking.current.contact?.fields;
    if(!contactDetails) {
        return null;
    }

    return (
        <SectionContainerBox>
            <SectionHeaderBox>
                <ManageMyBookingSectionTitleBox>
                    {services.language.translate('Booking Contact')}
                </ManageMyBookingSectionTitleBox>
                <ManageMyBookingEditSectionButtonComponent onClick={onClickHandler} label={services.language.translate('Modify')}/>
            </SectionHeaderBox>


            <BookingContactFieldsBox>
                <ReadonlyFieldComponent key={contactDetails.firstName.fieldName}
                                        fieldLabel={contactDetails.firstName.fieldName}
                                        fieldValue={contactDetails.firstName.value}/>
                <ReadonlyFieldComponent key={contactDetails.lastName.fieldName}
                                        fieldLabel={contactDetails.lastName.fieldName}
                                        fieldValue={contactDetails.lastName.value}/>
                <ReadonlyFieldComponent key={contactDetails.emailAddress.fieldName}
                                        fieldLabel={contactDetails.emailAddress.fieldName}
                                        fieldValue={contactDetails.emailAddress.value}/>
                <PhoneNumberInputComponent key={contactDetails.phoneNumber.fieldName}
                                           field={contactDetails.phoneNumber}
                                           countryField={contactDetails.countryCode}
                                           isReadOnly={true}/>
            </BookingContactFieldsBox>

        </SectionContainerBox>
    );
});
