import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PaypalLogoSmallV2Icon} from "../../../../assets/svg/paypal-logo-small-v2.icon";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    padding: ${props => props.theme.spacing.spacing16};
`

export const PaypalPaymentMethodTabHeaderComponent: React.FC = observer(() => {
    const screenMediaQuery = useContainerMediaQueriesChecks();
    const renderLabel = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <span>
                Paypal
            </span>
        );
    }

    return (
        <HeaderBox>
            <PaypalLogoSmallV2Icon/>
            {renderLabel()}
        </HeaderBox>
    );
})