import React, {PropsWithChildren, useEffect} from "react";
import {observer} from "mobx-react";
import {DISPATCH_ACTION, PayPalScriptProvider, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {useServices} from "../../../../hooks/use-services.hook";
import {reaction} from "mobx";

function prepareLocale(languageCode: string) {
    const parts = languageCode.split('-');
    return `${parts[0].toLowerCase()}_${parts[1].toUpperCase()}`;
}

const PayPalSdkReload: React.FC = observer(() => {
    const services = useServices();
    const [{options}, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        const r = reaction(() => {
            return {
                currency: services.currency.current,
                currentLanguage: services.language.currentLanguage
            }
        }, (data) => {
            dispatch({
                type: DISPATCH_ACTION.RESET_OPTIONS,
                value: {
                    ...options,
                    currency: data.currency,
                    locale: prepareLocale(data.currentLanguage)
                }
            })
        });

        return () => r();
    }, [dispatch, options, services.currency, services.language.currentLanguage]);

    return (
        <></>
    );
});

interface PayPalSdkLoaderProps extends  PropsWithChildren {

}

export const PayPalSdkLoader: React.FC<PayPalSdkLoaderProps> = observer((props) => {
    const services = useServices();


    if(!services.configuration.data.pspConfigs?.PayPal?.clientId) {
        return (
            <>
                {props.children}
            </>
        );
    }

/*
    const getBuyerCountry = () => {

        return services.configuration.defaultCountryCode;
    }
*/


    return (
        <PayPalScriptProvider options={{
            clientId: services.configuration.data.pspConfigs.PayPal.clientId,
            environment: services.configuration.data.pspConfigs.PayPal.environment,
            components: ['buttons'],
            intent: 'authorize',
            commit: true,
            currency: services.currency.current,
            //buyerCountry: getBuyerCountry(),
            //disableFunding: ['card', 'paylater', 'bancontact', 'blik', 'venmo'],
            locale: prepareLocale(services.language.currentLanguage)

        }}>
            <PayPalSdkReload/>
            {props.children}
        </PayPalScriptProvider>
    )
})