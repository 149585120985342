import React from "react";
import {useContainerMediaQueriesChecks} from "../../hooks/use-container-media-queries-checks.hook";
import {computePaymentMethodIconSize} from "./compute-payment-method-icon-size";
import {observer} from "mobx-react";
import {
    IRenderPaymentMethodIconOptions
} from "../../services/booking/models/payment/methods/payment-method.view-model.interface";


export const LogoVisaIcon: React.FC<IRenderPaymentMethodIconOptions> = observer((props) => {
    return (
        <svg {...computePaymentMethodIconSize(useContainerMediaQueriesChecks(), props)} viewBox="0 0 91 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M82.6249 0H7.62588C3.4838 0 0.125977 3.35782 0.125977 7.4999V52.4993C0.125977 56.6414 3.4838 59.9992 7.62588 59.9992H82.6249C86.767 59.9992 90.1248 56.6414 90.1248 52.4993V7.4999C90.1248 3.35782 86.767 0 82.6249 0Z"
                fill="#DFE3E8"/>
            <path
                d="M44.8629 21.3566L41.113 38.7002H36.613L40.363 21.3566H44.8629ZM63.7252 32.6065L66.0876 26.1003L67.4564 32.6065H63.7252ZM68.7314 38.7564H72.8938L69.2564 21.4129H65.5064C64.6813 21.4204 63.9418 21.9234 63.6314 22.6879L56.9003 38.7564H61.6252L62.5439 36.1689H68.1876L68.7314 38.7564ZM57.0315 33.1315C57.0503 28.4628 50.7503 28.1253 50.7503 26.2503C50.7503 25.6316 51.3503 24.9753 52.6253 24.8066C54.1427 24.6565 55.6724 24.9222 57.0503 25.5753L57.8378 21.8254C56.4946 21.3217 55.0723 21.0614 53.6378 21.0566C49.2129 21.0566 46.1379 23.4191 46.1379 26.7941C46.1379 29.2878 48.3691 30.6753 50.0566 31.5003C51.7441 32.3252 52.4003 32.9065 52.3816 33.6565C52.3816 34.819 50.9941 35.344 49.7004 35.3627C48.1045 35.3833 46.5298 34.996 45.1254 34.2377L44.3192 37.9877C45.9082 38.608 47.6009 38.9198 49.3066 38.9064C54.0128 38.9064 57.1065 36.5814 57.1253 32.9815L57.0315 33.1315ZM38.5443 21.3004L31.2694 38.6439H26.3757L22.7945 24.7878C22.7315 24.1283 22.3253 23.551 21.7257 23.2691C20.3181 22.5975 18.8314 22.1061 17.3008 21.8066L17.4133 21.3004H25.0444C26.0772 21.2962 26.9597 22.0435 27.1257 23.0629L29.0006 33.094L33.6693 21.3004H38.5443Z"
                fill="#2A2A6C"/>
        </svg>


    )
})

