import {PaymentMethodBaseModel} from "../payment-method-base.model";
import {PaymentMethodCategoryEnum} from "../payment-method-category.enum";

export abstract class CreditCardMethodModel extends PaymentMethodBaseModel {

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.CreditCard;
    }

    async canMakePayments(): Promise<boolean> {
        return true;
    }

}