import {IBeginPaymentInvoiceRequest, IClientPaymentCallbacks} from "./begin-payment-base.request";
import {IVoucherPaymentRequest} from "./voucher-payment.request";
import {BookingFlowTypeEnum} from "../../booking/booking-strategies/booking-flow-type.enum";
import {NullableString} from "../../../types/nullable-types";

export enum MobileWalletProviderEnum {
    GooglePay = 1,
    ApplePay = 2
}

export interface IBeginBookingPaymentRequest extends IBeginPaymentInvoiceRequest {
    dotRezToken: string;
    bookingTimestamp: number;
    attempt: number;
    paymentMethodCode: string;
    callbacks: IClientPaymentCallbacks;
    culture: string;
    vouchers: IVoucherPaymentRequest[];
    bookingFlow: BookingFlowTypeEnum,
    agentName: NullableString;
    mobileWalletProvider?: MobileWalletProviderEnum;
    mobileWalletToken?: NullableString;
}