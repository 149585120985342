import {PaymentStepBase} from "../common/payment-step-base";
import {IRoute} from "../../../navigation/models/route.interface";
import {IManageMyBookingRoutes} from "../../../navigation/routes/manage-my-booking/manage-my-booking.routes.interface";
import {BookingModel} from "../../models/booking.model";
import {Wizard} from "../../../../models/wizard/wizard";

export class ManageMyBookingPaymentStep extends PaymentStepBase<IManageMyBookingRoutes> {

    constructor(booking: BookingModel, wizard: Wizard, private readonly alwaysVisible?: boolean) {
        super(booking, wizard);
    }

    protected _shouldBeDisplayed(): boolean {
        if(this.alwaysVisible) {
            return true;
        }
        return super._shouldBeDisplayed();
    }

    protected get routes(): IManageMyBookingRoutes {
        return this.services.navigator.routes.manageMyBooking;
    }

    get route(): IRoute {
        return this.routes.payment;
    }

}
