import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {CheckboxComponent} from "../../basic-components/checkbox/checkbox.component";
import styled from "styled-components";
import {CardBox} from "../../basic-components/card/card.box";
import {
    IFlightSearchControllerViewModel,
} from "../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {PsoUserOptionsEnum} from "../../services/flight-search/flight-search-controller/pso.enums";

const CheckBoxContainer = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${props => props.theme.spacing.flexGap16};
    box-shadow: none;
    border: none;
`

const CheckBoxLabel = styled.div`
    flex-grow: 1;
    
`


interface PsoUserOptionComponentProps {
    optionValue: PsoUserOptionsEnum;
    searchController: IFlightSearchControllerViewModel;
    className?: string;
}

export const PsoUserOptionComponent: React.FC<PsoUserOptionComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.searchController;
    const psoMarket = searchController.getPsoMarket();
    if(!psoMarket) {
        return null;
    }

    const onClick = () => {
        if(props.optionValue === searchController.psoUserOption) {
            searchController.psoUserOption = PsoUserOptionsEnum.None;
        } else {
            searchController.psoUserOption = props.optionValue;
        }
    }

    const getLabel = () => {
        if(props.optionValue === PsoUserOptionsEnum.Resident) {
            if(psoMarket.isSicilia) {
                return services.language.translate('I am resident in Sicily')
            } else {
                return services.language.translationFor('I am a resident in {market}').withParams({market: psoMarket.getMarketNameTranslation()});
            }

        } else {
            if(psoMarket.isSicilia) {
                return services.language.translate('I work in Sicily');
            } else {
                return services.language.translationFor('I work in {market}').withParams({market: psoMarket.getMarketNameTranslation()});
            }

        }

    }


    return (
        <CheckBoxContainer className={props.className}
                           onClick={onClick}>
            <CheckboxComponent checked={searchController.psoUserOption === props.optionValue}
                               isReadOnly={true}/>
            <CheckBoxLabel>
                {getLabel()}
            </CheckBoxLabel>
        </CheckBoxContainer>
    )
});
