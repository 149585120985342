import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {reaction} from "mobx";
import {
    IGooglePayMethodViewModel
} from "../../../../services/booking/models/payment/methods/google-pay/google-pay-method.view-model.interface";
import styled from "styled-components";

const GooglePayButtonContainerBox = styled.div`
    ${props => props.theme.screenMediaQuery.smallScreen} {
        min-width: 140px;
    }
`

export const GooglePayButtonComponent: React.FC<{googlePayMethod: IGooglePayMethodViewModel}> = observer((props) => {

    const buttonContainerRef = useRef<HTMLDivElement | null>(null);
    const googlePayButtonRef = useRef<HTMLElement | null>(null);
    const googlePayMethod = props.googlePayMethod;


    useEffect(() => {

        const r = reaction(() => googlePayMethod.createGooglePayButton(),
            (button) => {
                const buttonContainer = buttonContainerRef.current;
                const currentGooglePayButton = googlePayButtonRef.current;
                if(buttonContainer && button) {
                    if(currentGooglePayButton) {
                        currentGooglePayButton.remove();
                    }

                    buttonContainer.appendChild(button);
                    googlePayButtonRef.current = button;
                }
            }, {
                fireImmediately: true
            })

        return () => r();

    }, [googlePayMethod]);


    return (
        <GooglePayButtonContainerBox ref={buttonContainerRef}/>
    )
})