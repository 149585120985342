import {PaymentTransactionStatusEnum} from "../../../../../airline-webapi/enums/payment-transaction-status.enum";
import {ValidationResultEnum} from "../../../../../../types/validation-result.enum";
import {PaymentMethodCodesEnum} from "../payment-method-codes.enum";
import {MobileWalletProviderEnum} from "../../../../../airline-webapi/requests/begin-booking-payment.request";
import {BookingModel} from "../../../booking.model";
import {
    CapacitorGooglePay,
    PaymentErrorStatusCodeEnum,
    StartPaymentRequest,
    StartPaymentResponse
} from "@jackobo/capacitor-google-pay";
import {IPspError} from "../../../../../airline-webapi/responses/psp-error";
import {IServiceFactory} from "../../../../../service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";

export interface IGooglePayConfigs {
    apiVersion: number,
    apiVersionMinor: number,
    gateway: string;
    gatewayMerchantId: string;
    merchantId: string;
    merchantName: string;
    environment: google.payments.api.Environment;
    allowedAuthMethods: google.payments.api.CardAuthMethod[];
    allowedCardNetworks: google.payments.api.CardNetwork[];
    allowCreditCards: boolean
    acquirerBankCountry: string;
}


export class GooglePaymentHandler {
    constructor(private readonly booking: BookingModel, private readonly configs: IGooglePayConfigs, private readonly paymentMethodCode: PaymentMethodCodesEnum) {
        if(!GooglePaymentHandler._initPluginPromise) {
            GooglePaymentHandler._initPluginPromise = this._initPlugin();
        }

        GooglePaymentHandler._initPluginPromise.then(() => {
            return this._initGooglePayClient();
        })

        makeObservable<this, '_googlePaymentsClient'>(this, {
            _googlePaymentsClient: observable.ref
        });

    }


    private _googlePaymentsClient: google.payments.api.PaymentsClient | null = null;

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private static _initPluginPromise: Promise<void>;

    private async _initPlugin(): Promise<void> {
        await CapacitorGooglePay.initializeClient({
            environment: this.configs.environment,
            merchantInfo: {
                merchantId: this.configs.merchantId,
                merchantName: this.configs.merchantName
            }
        })
    }

    private async _initGooglePayClient(): Promise<void> {
        if(this.services.device.isAndroidMobileHybrid) {
            // because only for Android hybrid we need the Google Pay script in order to render the button
            // For iOS hybrid Google Pay is not available anyway
            // and for web Google Pay script is loaded by the plugin.
            await this._loadGooglePayScript();
        }

        const client = await this._createGooglePayClient();
        runInAction(() => {
            this._googlePaymentsClient = client;
        });
    }


    public async isReadyToPay(): Promise<boolean> {
        await GooglePaymentHandler._initPluginPromise;

        const response = await CapacitorGooglePay.isReadyToPay({
            apiVersion: this.configs.apiVersion,
            apiVersionMinor: this.configs.apiVersionMinor,
            existingPaymentMethodRequired: false,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: this.configs.allowedAuthMethods,
                        allowedCardNetworks: this.configs.allowedCardNetworks
                    }
                }
            ],

        });

        return response.result;
    }

    createGooglePayButton(): HTMLElement | null {

        if(!this._googlePaymentsClient) {
            return null;
        }

        return this._googlePaymentsClient.createButton({
            buttonType: this.services.screenMediaQuery.xsAndBelow ? "short" : "book",
            buttonColor: "black",
            buttonLocale: this.services.language.currentLanguageIso2,
            buttonRadius: 8,
            buttonSizeMode: "fill",
            onClick: async (event: Event) => {
                await this._onButtonClick(event);
            }
        });
    }


    private _authorizePayment = async (paymentData: StartPaymentResponse): Promise<void> => {

        await this.booking.paymentHandler.startBookingPayment({
            paymentMethodCode: this.paymentMethodCode,
            mobileWalletProvider: MobileWalletProviderEnum.GooglePay,
            mobileWalletToken: JSON.stringify(paymentData.paymentMethodData),
            skipValidations: true
        });
    }


    private async _onButtonClick(event: Event): Promise<void> {
        const validationResult = await this.booking.paymentHandler.performStartBookingPaymentValidations();
        if(validationResult.result !== ValidationResultEnum.Success) {
            event.preventDefault();
            return;
        }

        let paymentData: StartPaymentResponse;

        try {
            paymentData = await CapacitorGooglePay.startPayment(this._createPaymentRequest());
        } catch (err: any) {

            if(err?.code !== PaymentErrorStatusCodeEnum.Canceled) {
                this.booking.services.logger.error('Google Pay failed', err);
                const errorDetails: IPspError[] = [];
                if(err?.code) {
                    errorDetails.push({
                        code: err.code,
                        message:  err.message ?? err.code
                    })
                }
                await this.booking.paymentHandler.onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Error,
                    pspErrorDetails: errorDetails,
                    apiErrorCode: null,
                    shouldRefreshBooking: false
                })
            }

            return;
        }

        await this._authorizePayment(paymentData);
    }

    private _createPaymentRequest(): StartPaymentRequest {
        const booking = this.booking;
        return {
            apiVersion: this.configs.apiVersion,
            apiVersionMinor: this.configs.apiVersionMinor,
            //callbackIntents: ["PAYMENT_AUTHORIZATION"],
            emailRequired: false,
            shippingAddressRequired: false,
            shippingOptionRequired: false,
            merchantInfo: {
                merchantId: this.configs.merchantId,
                merchantName: this.configs.merchantName
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                //totalPriceLabel: this.services.language.translate('Total'),
                totalPrice: booking.balanceDue.amount.toFixed(2),
                currencyCode: booking.balanceDue.currency,
                countryCode: this.configs.acquirerBankCountry
            },
            allowedPaymentMethods: [
                {
                    type: "CARD",
                    parameters: {
                        allowedAuthMethods: this.configs.allowedAuthMethods,
                        allowedCardNetworks: this.configs.allowedCardNetworks,
                        allowCreditCards: true,
                        allowPrepaidCards: true,
                        //cvcRequired: true,
                        //assuranceDetailsRequired: true,
                        billingAddressRequired: false,
                        billingAddressParameters: {
                            format: "MIN",
                            phoneNumberRequired: false
                        }
                    },
                    tokenizationSpecification: {
                        type: "PAYMENT_GATEWAY",
                        parameters: {
                            gateway: this.configs.gateway,
                            gatewayMerchantId: this.configs.gatewayMerchantId
                        }
                    }
                }
            ]
        }
    }

    private async _loadGooglePayScript(): Promise<void> {
        await this.services.scriptLoader.loadScript({
            url: "https://pay.google.com/gp/p/js/pay.js"
        });
    }

    private async _createGooglePayClient(): Promise<google.payments.api.PaymentsClient | null> {
        try {

            if(!google?.payments?.api?.PaymentsClient) {
                return null;
            }


            return new google.payments.api.PaymentsClient({
                environment: this.configs.environment,
                merchantInfo: {
                    merchantId: this.configs.merchantId,
                    merchantName: this.configs.merchantName
                }
            });

        } catch (err) {
            this.services.logger.error('Failed to load Google Pay client');
            return null;
        }
    }
}