import {CreditCardMethodModel} from "./credit-card-method.model";
import React from "react";
import {LogoMastercardIcon} from "../../../../../../assets/svg/logo-mastercard.icon";
import {IRenderPaymentMethodIconOptions} from "../payment-method.view-model.interface";

export class MastercardMethodModel extends CreditCardMethodModel {
    get name(): string {
        return "Mastercard";
    }

    renderIcon(options?: IRenderPaymentMethodIconOptions): React.ReactElement | null {
        return (<LogoMastercardIcon {...options}/>);
    }
}