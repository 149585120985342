import {BookingModel} from "../../booking.model";
import {PaymentMethodCodesEnum} from "./payment-method-codes.enum";
import {VisaMethodModel} from "./card/visa-method.model";
import {MastercardMethodModel} from "./card/mastercard-method.model";
import {MaestroMethodModel} from "./card/maestro-method.model";
import {ApplePayMethodModel} from "./apple-pay/apple-pay-method.model";
import {GooglePayMethodModel} from "./google-pay/google-pay-method.model";
import {PaypalPaymentMethodModel} from "./paypal/paypal-payment-method.model";
import {IPaymentMethodViewModel} from "./payment-method.view-model.interface";

export class PaymentMethodFactory {
    public static createPaymentMethod(booking: BookingModel, code: string): IPaymentMethodViewModel | null {
        switch (code) {
            case PaymentMethodCodesEnum.VisaPaymentHub:
            case PaymentMethodCodesEnum.VisaNewsky:
                return new VisaMethodModel(booking, code);
            case PaymentMethodCodesEnum.MasterCardPaymentHub:
            case PaymentMethodCodesEnum.MastercardNewsky:
                return new MastercardMethodModel(booking, code);
            case PaymentMethodCodesEnum.MaestroPaymentHub:
                return new MaestroMethodModel(booking, code);
            case PaymentMethodCodesEnum.ApplePay:
                return new ApplePayMethodModel(booking, code);

            case PaymentMethodCodesEnum.GooglePay:
                return new GooglePayMethodModel(booking, code);
            case PaymentMethodCodesEnum.PayPal:
                return new PaypalPaymentMethodModel(booking, code);
            default:
                return null;

        }
    }
}