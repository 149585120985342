import {PaymentMethodTabModel} from "../payment-method-tab.model";
import React from "react";
import {PaymentMethodTabEnum} from "../payment-method-tab.enum";
import {
    GooglePayPaymentMethodTabHeaderComponent
} from "../../../../../../pages/booking-flow/payment/google-pay/google-pay-payment-method-tab-header.component";
import {
    GooglePayPaymentMethodTabContentComponent
} from "../../../../../../pages/booking-flow/payment/google-pay/google-pay-payment-method-tab-content.component";
import {
    GooglePayButtonComponent
} from "../../../../../../pages/booking-flow/payment/google-pay/google-pay-button.component";
import {IGooglePayMethodViewModel} from "../../methods/google-pay/google-pay-method.view-model.interface";
import {BookingModel} from "../../../booking.model";

export class GooglePayTabModel extends PaymentMethodTabModel {
    constructor(booking: BookingModel) {
        super(PaymentMethodTabEnum.GooglePay, booking);
    }

    get googlePayMethod(): IGooglePayMethodViewModel | null {
        return this.paymentHandler.googlePayMethod;
    }

    async isAvailable(): Promise<boolean> {

        if(this.googlePayMethod) {
            return await this.googlePayMethod.canMakePayments();
        }

        return false;
    }


    renderTabHeader(): React.ReactElement {
        return (
            <GooglePayPaymentMethodTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <GooglePayPaymentMethodTabContentComponent/>
        );
    }

    renderPayButton(): React.ReactElement {
        if(!this.googlePayMethod) {
            return (
                <></>
            )
        }

        return (
            <GooglePayButtonComponent googlePayMethod={this.googlePayMethod}/>
        );
    }

}