import {PaymentMethodBaseModel} from "./payment-method-base.model";
import {PaymentMethodCategoryEnum} from "./payment-method-category.enum";
import {
    PaymentSupportedOS,
    PaymentSupportedPlatformTypes
} from "../../../../configuration/objects/payments-configuration.interface";

export abstract class MobileWalletPaymentMethodModel extends PaymentMethodBaseModel {


    protected abstract _getSupportedOS(): PaymentSupportedOS;
    protected abstract _getSupportedPlatformsTypes(): PaymentSupportedPlatformTypes;

    protected abstract _canMakePayments(): Promise<boolean>;

    async canMakePayments(): Promise<boolean> {
        if(await this._isSupportedOnCurrentDevice()) {
            try {
                return await this._canMakePayments();
            } catch (err) {
                this.services.logger.error(`Failed to check if can make payments with ${this.code}`, err);
                return false;
            }

        }
        return false;
    }

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.MobileWallet;
    }

    private async _isSupportedOnCurrentOS(): Promise<boolean> {
        const supportedOS = this._getSupportedOS() ?? [];
        if(supportedOS.includes('all')) {
            return true;
        }
        const currentOS = await this.services.device.getOperatingSystemName();
        return supportedOS.includes(currentOS);
    }

    private _isSupportedOnCurrentPlatTypeform(): boolean {
        const supportedPlatforms = this._getSupportedPlatformsTypes() ?? [];
        if(supportedPlatforms.includes('all')) {
            return true;
        }

        return supportedPlatforms.includes(this.services.device.getPlatformType())
    }

    private async _isSupportedOnCurrentDevice(): Promise<boolean> {
        return this._isSupportedOnCurrentPlatTypeform() && (await this._isSupportedOnCurrentOS());
    }
}