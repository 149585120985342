import React from "react";
import styled, {css} from "styled-components";
import { IPaymentMethodViewModel } from "../../../../services/booking/models/payment/methods/payment-method.view-model.interface";
import {observer} from "mobx-react-lite";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";
import {CardBox} from "../../../../basic-components/card/card.box";
import {useServices} from "../../../../hooks/use-services.hook";


const CreditCardPaymentMethodBox = styled(CardBox)<{$isSelected: boolean; $disabled: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing24};
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    box-shadow: ${props => props.theme.boxShadow.shadow2};
    cursor: ${props => props.$disabled ? "default" : "pointer"};
    border: none;
    ${
            props => props.$isSelected
                    ? css`
                        border: 1px solid ${props => props.theme.colors.success};
                    `
                    : ``

    }

    ${props => props.theme.containerMediaQuery.smallScreen} {
        gap: ${props => props.theme.spacing.spacing16};
        padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing8};
    }
`

const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

const BrandNameBox = styled.div`
    
`

interface CreditCardPaymentMethodComponentProps {
    paymentMethod: IPaymentMethodViewModel;
}
export const CreditCardPaymentMethodComponent: React.FC<CreditCardPaymentMethodComponentProps> = observer((props) => {
    const services = useServices();

    const isDisabled = services.booking.current.balanceDue.amount <= 0;
    const onClickHandler = async () => {
        if(!isDisabled) {
            props.paymentMethod.isSelected = !props.paymentMethod.isSelected
        }

    }


    return (
        <CreditCardPaymentMethodBox onClick={onClickHandler} $isSelected={props.paymentMethod.isSelected} $disabled={isDisabled}>
            {props.paymentMethod.renderIcon()}

            <BottomSectionBox>
                <BrandNameBox>
                    {props.paymentMethod.name}
                </BrandNameBox>

                {!isDisabled && <CheckboxComponent checked={props.paymentMethod.isSelected}/>}
            </BottomSectionBox>

        </CreditCardPaymentMethodBox>
    )
});
