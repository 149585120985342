import {IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {BookingModel} from "../booking.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {
    ILoadPaymentMethodsOptions,
    IOnPayBookingFailedParams,
    IPaymentHandlerViewModel,
    IStartBookingPaymentOptions,
    IStartBookingPaymentResult
} from "./payment-handler.view-model.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {TimeSpan} from "../../../../types/time-span";
import {IBeginPaymentStrategyOption, IPaymentStrategy} from "./strategies/payment-strategy.interface";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {PaymentStatusObserver} from "./payment-status-observer";
import {PaymentResultsDialogFactory} from "./payment-results-dialog-factory";
import {PaymentStatusQueryParamEnum, PaymentStatusQueryParamValuesEnum} from "./payment-status-query-params.enum";
import {PaymentTransactionStatusEnum} from "../../../airline-webapi/enums/payment-transaction-status.enum";
import {delay} from "../../../../utils/util-functions";
import {Check} from "../../../../types/type-checking";
import {WebapiPaymentStrategy} from "./strategies/webapi-payment.strategy";
import {IPaymentStatusResponse} from "../../../airline-webapi/responses/payment-status.response";
import {PaymentMethodsTabsModel} from "./tabs/payment-methods-tabs.model";
import {IPaymentMethodViewModel} from "./methods/payment-method.view-model.interface";
import {ApplePayMethodModel} from "./methods/apple-pay/apple-pay-method.model";
import {GooglePayMethodModel} from "./methods/google-pay/google-pay-method.model";
import {NullableString} from "../../../../types/nullable-types";
import {PaypalPaymentMethodModel} from "./methods/paypal/paypal-payment-method.model";
import {PaymentMethodCategoryEnum} from "./methods/payment-method-category.enum";

export class PaymentHandlerModel implements IPaymentHandlerViewModel /*, IRoutingGuard*/ {

    constructor(private readonly booking: BookingModel) {

        this._selectedPaymentMethodCode = this.booking.storage.getItem(BookingSessionStorageKeys.selectedPaymentMethod);

        makeObservable<this, '_allPaymentMethods' | '_selectedPaymentMethodCode'>(this, {
            _selectedPaymentMethodCode: observable.ref,
            _allPaymentMethods: observable.ref
        });

        this._paymentStrategy = new WebapiPaymentStrategy(booking);
        this.resultDialogs = new PaymentResultsDialogFactory(this.booking);



        //_initPaymentMethodsOnPaymentHandlerCreation call is necessary here for the case when the user refresh the page while in the payment page.
        this._initPaymentMethodsOnPaymentHandlerCreation().finally(() => {
            if(this._shouldFinalizePayment) {
                return this._finalizePayment(this.services.navigator.getQueryParamsValues(PaymentStatusQueryParamEnum.Status)[PaymentStatusQueryParamEnum.Status] as PaymentStatusQueryParamValuesEnum);
            } else if(this._shouldTryFinalizePendingPayment) {
                return this._tryFinalizePendingPayment()
            }
        })


        //this._routingGuardSubscription = this.services.navigator.registerRoutingGuard(this);
    }

    private readonly _paymentStrategy: IPaymentStrategy;
    readonly resultDialogs: PaymentResultsDialogFactory;
    //private readonly _routingGuardSubscription: IRoutingGuardSubscription;
    private _selectedPaymentMethodCode: NullableString = null;
    private _preventDoublePayBooking = false;
    private _activateFromPaymentReaction: IReactionDisposer | null = null;
    private _redirectPromiseResolver: null | ((value: void | PromiseLike<void>) => void) = null;

    dispose(): void {
        //this._routingGuardSubscription.unsubscribe();
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private get session(): IDotRezBookingSession {
        return this.booking.session;
    }

    private _paymentMethodsTabs: PaymentMethodsTabsModel | null = null;

    get paymentMethodsTabs(): PaymentMethodsTabsModel {
        if(!this._paymentMethodsTabs) {
            this._paymentMethodsTabs = new PaymentMethodsTabsModel(this.booking);
        }
        return this._paymentMethodsTabs;
    }

    private _allPaymentMethods: IPaymentMethodViewModel[] = [];
    get allPaymentMethods(): IPaymentMethodViewModel[] {
//        return this._paymentStrategy.allPaymentMethods;
        return this._allPaymentMethods;
    }
    get creditCardPaymentMethods(): IPaymentMethodViewModel[] {
        return this.allPaymentMethods.filter(pm => pm.category === PaymentMethodCategoryEnum.CreditCard);
    }

    private _findPaymentMethodByInstance<TMethod>(instanceConstructor: new (...args: any[]) => TMethod): TMethod | null {
        const method = this.allPaymentMethods.find(pm => pm instanceof instanceConstructor);
        if(method) {
            return method as TMethod;
        }
        return null;
    }

    get applePayMethod(): ApplePayMethodModel | null {
        return this._findPaymentMethodByInstance(ApplePayMethodModel);
    }
    get googlePayMethod(): GooglePayMethodModel | null {
        return this._findPaymentMethodByInstance(GooglePayMethodModel);
    }

    get payPalMethod(): PaypalPaymentMethodModel | null {
        return this._findPaymentMethodByInstance(PaypalPaymentMethodModel);
    }


    selectPaymentMethod(code: string): void {

        runInAction(() => {
            this._selectedPaymentMethodCode = this.allPaymentMethods.find(m => m.code === code)?.code ?? null;
            if(this._selectedPaymentMethodCode) {
                this.booking.storage.setItem(BookingSessionStorageKeys.selectedPaymentMethod, this._selectedPaymentMethodCode);
            } else {
                this.booking.storage.removeItem(BookingSessionStorageKeys.selectedPaymentMethod);
            }

        })
    }

    async loadPaymentMethods(options: ILoadPaymentMethodsOptions): Promise<void> {
        await this._paymentStrategy.loadPaymentMethods(options);
        await this._initPaymentMethods();
    }

    private async _initPaymentMethodsOnPaymentHandlerCreation(): Promise<void> {
        if(this.booking.bookingStrategyAdapter.getPaymentRoute().equals(this.services.navigator.currentRoute)) {
            await this._initPaymentMethods();
        }
    }
    private async _initPaymentMethods(): Promise<void> {
        const availablePaymentMethods: IPaymentMethodViewModel[] = [];
        for(let pm of this._paymentStrategy.allPaymentMethods) {
            if(await pm.canMakePayments()) {
                availablePaymentMethods.push(pm);
            }
        }
        runInAction(() => {
            this._allPaymentMethods = availablePaymentMethods;
        });

        await this.paymentMethodsTabs.loadTabs();
    }


    unselectPaymentMethod(code: string): void {
        if(this.getSelectedPaymentMethod()?.code !== code) {
            return;
        }
        runInAction(() => {
            this._selectedPaymentMethodCode = null;
            this.booking.storage.removeItem(BookingSessionStorageKeys.selectedPaymentMethod);
        })
    }

    isPaymentMethodSelected(code: string): boolean {
        return this._selectedPaymentMethodCode === code;
    }

    getSelectedPaymentMethod(): IPaymentMethodViewModel | null {
        return this.allPaymentMethods.find(pm => pm.isSelected) ?? null;

    }

    private async _refreshBookingData(): Promise<void> {
        const bookingData = await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
        this.booking.updateBookingSessionData(bookingData);
    }

    private async _executePostSaveBookingOperations(): Promise<void> {
        if (this.booking.voucher.isApplied) {
            this.booking.voucher.clear();
        }

        this.booking.saveToMyTrips();

        // old purchase event
        //this.booking.sendAnalyticsPurchaseEvent();
        this.services.analytics.paymentEvents.logPurchase(this.booking)

        //await this.booking.moveBetweenDisruptionQueues();

        try {
            if(this.booking.getAllPassengersSegments().some(p => p.isCheckedIn)) {
                await this.services.airlineWebapi.updateBoardingPassesInWallets({
                    dotRezToken: this.session.token
                })
            }
        } catch (err) {
            this.services.logger.error(`Failed to update boarding passes in wallets for PNR ${this.booking.recordLocator}`, err);
        }
    }

    private async _saveZeroBalanceDueBooking(): Promise<IStartBookingPaymentResult> {
        const result = await this.services.loadingIndicator.execute({
            action: async () => {
                if(this.booking.voucher.isApplied) {
                    const voucherApplyResult = await this.booking.voucher.addVoucherToBooking();
                    if(voucherApplyResult !== ValidationResultEnum.Success) {
                        this.services.alert.showError(this.services.language.translate('Sorry! There was an error trying to add your voucher to the booking'));
                        return null;
                    }
                }
                try {
                    await this.booking.commitBooking();
                    await this._refreshBookingData();
                } catch (err) {
                    this.services.logger.error('Payment handler failed to save booking', err);
                    return ValidationResultEnum.Failure;
                }

                await this._executePostSaveBookingOperations();
                return ValidationResultEnum.Success;
            }
        });

        if(result !== ValidationResultEnum.Success) {
            const msg = this.services.language.translate('There was an error saving your booking. Please try again latter.');
            this.services.alert.showError(msg);
            return this._errorPayBookingResponse(msg);
        } else {
            await this.booking.bookingStrategyAdapter.onPaymentSuccess();
            this.resultDialogs.showPaymentSuccess({customMessage: this.services.language.translate('Booking saved!')});
            return {
                transactionStatus: PaymentTransactionStatusEnum.Completed,
                pspErrorDetails: [],
                pspRef: null,
                apiErrorCode: null,
                redirectUrl: null
            }
        }
    }

    private async _savePayedBooking(): Promise<void> {
        try {
            await this.booking.commitBooking();
            await this._refreshBookingData();
        } catch (err) {
            this.services.logger.error('Payment handler failed to save payed booking', err);
        } finally {
            //If the booking is paid it doesn't matter the outcome of the commitBooking and _refreshBookingData.
            //We need to give the user the ticket because the booking is paid
            await this._executePostSaveBookingOperations();
        }
    }

    private async _onPayBookingSuccess(): Promise<void> {
        this.paymentAttempt++;
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._savePayedBooking();
            }
        });

        this.services.analytics.paymentEvents.logAddRemarketingPurchase(this.booking);
        this._paymentStrategy.onPaymentFinalized();
        await this.booking.bookingStrategyAdapter.onPaymentSuccess();
        this.resultDialogs.showPaymentSuccess();


    }



    async onPayBookingFailed(options: IOnPayBookingFailedParams): Promise<void> {

        try {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    if(options.shouldRefreshBooking) {
                        await this._refreshBookingData();
                    }
                    await this.loadPaymentMethods({showLoadingIndicator: false});
                }
            })
        } catch (err) {
            this.services.logger.error('Failed to refresh booking and reload payment methods after failed payment', err);
        }

        if(options.status === PaymentTransactionStatusEnum.FatalError) {
            this.resultDialogs.showPaymentFatalError({
                pspErrorDetails: options.pspErrorDetails
            });
            return;
        }

        if(options.apiErrorCode && this.booking.errorHandling.canHandleApiError(options.apiErrorCode)) {
            this._paymentStrategy.onPaymentFinalized();
            await this.booking.errorHandling.handleApiError(options.apiErrorCode);
        } else {
            switch (options.status) {
                case PaymentTransactionStatusEnum.Cancel:
                    this._paymentStrategy.onPaymentFinalized();
                    //this.resultDialogs.showPaymentCanceled();
                    break;
                default:
                    this._paymentStrategy.onPaymentFinalized();
                    this.resultDialogs.showPaymentFailed({
                        errorMessage: null,
                        pspErrorDetails: options.pspErrorDetails
                    });
                    break;
            }
        }
    }

    private async _tryRefreshToken(): Promise<ValidationResultEnum> {
        try {
            const result = await this.booking.session.tryRefreshSession();
            if(result.isExpired) {
                return ValidationResultEnum.Failure;
            } else {
                return ValidationResultEnum.Success;
            }

        } catch (err) {
            this.services.alert.showError(this.services.language.translate('There was an error trying to initialize your payment transaction'));
            return ValidationResultEnum.Failure;
        }
    }

    async performStartBookingPaymentValidations(): Promise<{result: ValidationResultEnum; errorMessage?: string}> {
        if (this.booking.balanceDue.amount < 0) {
            this.services.logger.error('Negative booking cannot be payed');
            const msg = this.services.language.translate('Booking with negative balance due cannot be saved');
            this.services.alert.showError(msg);
            return {
                result: ValidationResultEnum.Failure,
                errorMessage: msg
            }
        }

        if(this.booking.validateTravelConditionsAcceptance() !== ValidationResultEnum.Success) {
            return {
                result: ValidationResultEnum.Failure,
                errorMessage: this.booking.travelConditionsAcceptanceError!
            }
        }

        if(this.booking.balanceDue.amount > 0) {
            if(this.booking.invoice.invoiceRequested) {
                if(ValidationResultEnum.Success !== await this.booking.invoice.validate()) {
                    const msg = this.services.language.translate('Invalid invoice fields values');
                    this.services.alert.showError(msg);
                    return {
                        result: ValidationResultEnum.Failure,
                        errorMessage: msg
                    }
                }
            }
        }

        return {
            result: ValidationResultEnum.Success
        };
    }

    async startBookingPayment(options?: IStartBookingPaymentOptions): Promise<IStartBookingPaymentResult> {
        if(this._preventDoublePayBooking) {
            return this._canceledPayBookingResponse();
        }

        this._preventDoublePayBooking = true;

        try {

            if(options?.paymentMethodCode) {
                this.selectPaymentMethod(options.paymentMethodCode);
            }

            if(!options?.skipValidations) {
                const validationsResult = await this.performStartBookingPaymentValidations();
                if(validationsResult.result !== ValidationResultEnum.Success) {
                    return this._errorPayBookingResponse(validationsResult.errorMessage ?? "");
                }
            }

            if(this.booking.balanceDue.amount === 0) {
                return await this._saveZeroBalanceDueBooking();
            }

            const refreshTokenResult = await this.services.loadingIndicator.execute({
                action: async () => {
                    return this._tryRefreshToken();
                }
            });

            if(refreshTokenResult !== ValidationResultEnum.Success) {
                return this._errorPayBookingResponse(this.services.language.translate('Your session has expired'));
            }

            const selectedPaymentMethod =  this.getSelectedPaymentMethod();
            if(!selectedPaymentMethod) {
                const msg = this.services.language.translate('Please select a payment method');
                this.services.alert.showError(msg);
                return this._errorPayBookingResponse(msg);
            }

            this.services.analytics.paymentEvents.logAddPaymentInfo(this.booking);

            try {
                return await this._beginPayment(selectedPaymentMethod, options);
            } catch (err) {
                this.services.logger.error('_beginPayment failed', err);
                await this.onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Error,
                    shouldRefreshBooking: true,
                    apiErrorCode: null,
                    pspErrorDetails: null
                });
                return this._errorPayBookingResponse(this.getGenericPaymentError());
            }
        } finally {
            this._preventDoublePayBooking = false;
        }
    }

    clearPaymentMethodSelection(): void {
        runInAction(() => {
            this._selectedPaymentMethodCode = null;
            this.booking.storage.removeItem(BookingSessionStorageKeys.selectedPaymentMethod);
        })

    }

    getGenericPaymentError(): string {
        return this.booking.errorHandling.getGenericPaymentError();
    }


    async finalizePspOrder(): Promise<void> {
        try {

            const response = await this.services.loadingIndicator.execute({
                action: async () => {
                    return await this._paymentStrategy.finalizePspOrder();
                }
            });

            if(response.paymentStatus === PaymentTransactionStatusEnum.Completed) {
                await this._onPayBookingSuccess();
                return;
            }

            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.session.resumeSessionTimer();
                }
            });


            if(await this.booking.session.isExpired()) {
                return;
            }

            switch (response.paymentStatus) {
                case PaymentTransactionStatusEnum.PendingReview:
                    this.resultDialogs.showPaymentInReview()
                    break;
                default:
                    await this.onPayBookingFailed({
                        status: response.paymentStatus,
                        shouldRefreshBooking: true,
                        apiErrorCode: response.apiErrorCode,
                        pspErrorDetails: response.pspErrorDetails
                    });

                    break;
            }

        } catch (err) {
            this.services.logger.error('finalizeMobileWalletPayment failed!', err);
            await this.onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: true,
                apiErrorCode: null,
                pspErrorDetails: null
            });
        } finally {
            this.clearPaymentMethodSelection();
        }
    }

    async cancelPspOrder(): Promise<void> {
        try {

            this._paymentStrategy.cancelPspOrder(); //no need to wait for the promise;

            await this.booking.session.resumeSessionTimer();

            if(!await this.booking.session.isExpired()) {
                await this.onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Cancel,
                    shouldRefreshBooking: false,
                    apiErrorCode: null,
                    pspErrorDetails: null
                });
            }


        } finally {
            this.clearPaymentMethodSelection();
        }

    }


    private _canceledPayBookingResponse(): IStartBookingPaymentResult {
        return {
            transactionStatus: PaymentTransactionStatusEnum.Cancel,
            pspRef: null,
            apiErrorCode: null,
            redirectUrl: null,
            pspErrorDetails: []
        }
    }

    private _errorPayBookingResponse(message: string): IStartBookingPaymentResult {
        return {
            transactionStatus: PaymentTransactionStatusEnum.Error,
            pspRef: null,
            redirectUrl: null,
            apiErrorCode: null,
            pspErrorDetails: [
                {
                    code: "1",
                    message: message
                }
            ]
        }
    }


    private get paymentAttempt(): number {
        const attempt = this.booking.storage.getItem(BookingSessionStorageKeys.paymentAttempt);
        if(attempt) {
            return parseInt(attempt);
        }

        return 1;
    }

    private set paymentAttempt(value: number) {
        this.booking.storage.setItem(BookingSessionStorageKeys.paymentAttempt, value.toString())
    }

    private async _beginPayment(selectedMethod: IPaymentMethodViewModel, options?: IStartBookingPaymentOptions): Promise<IStartBookingPaymentResult> {


        const strategyBeginPaymentResponse = await this.services.loadingIndicator.execute({
            action: async () => {
                const beginPaymentRequest: IBeginPaymentStrategyOption = {
                    selectedMethod: selectedMethod,
                    paymentAttempt: this.paymentAttempt
                }
                if(options?.mobileWalletToken) {
                    beginPaymentRequest.mobileWalletToken = options.mobileWalletToken;
                    beginPaymentRequest.mobileWalletProvider = options.mobileWalletProvider;
                }
                return await this._paymentStrategy.beginPayment(beginPaymentRequest);
            }
        });


        switch (strategyBeginPaymentResponse.transactionStatus) {
            case PaymentTransactionStatusEnum.Error:
            case PaymentTransactionStatusEnum.FatalError:
            case PaymentTransactionStatusEnum.Cancel:
                this.booking.paymentHandler.clearPaymentMethodSelection();
                await this.onPayBookingFailed({
                    status: strategyBeginPaymentResponse.transactionStatus,
                    apiErrorCode: strategyBeginPaymentResponse.apiErrorCode,
                    shouldRefreshBooking: true,
                    pspErrorDetails: strategyBeginPaymentResponse.pspErrorDetails
                });
                break;

            case PaymentTransactionStatusEnum.Completed:
                await this._onPayBookingSuccess();
                break;

            case PaymentTransactionStatusEnum.NotFinalized:
                this.booking.session.pauseSessionTimer();
                if(strategyBeginPaymentResponse.redirectUrl) {
                    await this._payRedirect(strategyBeginPaymentResponse);
                }
               break;

            default:
                throw new Error(`Invalid begin payment status ${strategyBeginPaymentResponse.transactionStatus}`);
        }

        return strategyBeginPaymentResponse;
    }

    private _getPaymentStatusFromUrl(url: string): PaymentStatusQueryParamValuesEnum | null {
        url = url?.toString()?.toLowerCase();
        if(!url) {
            return null;
        }


        if(0 <= url.indexOf('mobileapp/finalize')) {
            const urlBuilder = new URL(url);
            const callBackTypeParamValue = urlBuilder.searchParams.get('callbacktype')?.toString();
            switch (callBackTypeParamValue) {
                case '0':
                    return PaymentStatusQueryParamValuesEnum.Success;
                case '1':
                    return PaymentStatusQueryParamValuesEnum.Canceled;
                default:
                    return PaymentStatusQueryParamValuesEnum.Failed;
            }
        }


        return null;
    }



    private _disposeActivateFromPaymentReaction(): void {
        if(this._activateFromPaymentReaction) {
            this._activateFromPaymentReaction();
            this._activateFromPaymentReaction = null;
        }
    }

    private _resolveRedirectPromise = () => {
        if(this._redirectPromiseResolver) {
            this._redirectPromiseResolver();
            this._redirectPromiseResolver = null;
        }
    }

    private async _payRedirect(strategyBeginPaymentResponse: IStartBookingPaymentResult): Promise<void> {
        if(!strategyBeginPaymentResponse.redirectUrl) {
            this.services.logger.error(`Missing payment redirect URL`);
            await this.onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: true,
                apiErrorCode: null,
                pspErrorDetails: null
            });
            return;
        }

        if(this.services.device.isHybrid) {
            await this._payRedirectWithAdvancedInAppBrowser(strategyBeginPaymentResponse.redirectUrl!);
        } else {

            await this._payRedirectWithNavigation(strategyBeginPaymentResponse.redirectUrl!)
        }
    }

    private async _payRedirectWithAdvancedInAppBrowser(redirectUrl: string): Promise<void> {


        let lastUrl = "";

        const advancedInAppBrowser = await this.services.loadingIndicator.execute({
            action: async () => {
                return await this.services.window.openAdvancedInAppBrowser({
                    url: redirectUrl,
                    theme: this.services.theme.currentTheme,
                    enableDebug: !this.services.configuration.isProduction,
                    urlPatternsToOpenInExternalBrowser: [
                        '/cookies-policy',
                        '/privacy-notice',
                        '/terms-portal',
                        '/privacy',
                        '/terms'
                    ],
                    onUrlChanged: async (url) => {
                        lastUrl = url;
                        //this.services.logger.error('urlChanged: ' + url);
                        if(!Check.isNullOrUndefined(this._getPaymentStatusFromUrl(url))) {
                            await advancedInAppBrowser?.close();
                        }
                    }
                });
            }
        });

        this.booking.session.pauseSessionTimer();

        await advancedInAppBrowser.wait();

        await this.booking.session.resumeSessionTimer();


        const paymentStatusFromUrl = this._getPaymentStatusFromUrl(lastUrl);
        if(Check.isNullOrUndefined(paymentStatusFromUrl)) {
            await this._tryFinalizePendingPayment();
        } else {
            await this._finalizePayment(paymentStatusFromUrl);
        }

    }

    private async _payRedirectWithNavigation(redirectUrl: string): Promise<void> {
        this.booking.session.pauseSessionTimer();
        //This reaction here is because in production after redirect from payment page back to the website
        //the browser might have cached our payment page so instead of a full page load it just activates the page
        this._activateFromPaymentReaction = reaction(() => this.services.application.isActive,
            async (isActive) => {
                if(isActive) {

                    await this.booking.session.resumeSessionTimer();

                    if(this._shouldTryFinalizePendingPayment) {
                        await this._tryFinalizePendingPayment();
                    }

                    this._disposeActivateFromPaymentReaction();
                    this._resolveRedirectPromise();
                }
            });

        const resultPromise =  new Promise<void>((resolve) => {
            this._redirectPromiseResolver = resolve;
        });

        await this.services.loadingIndicator.execute({
            action: async () => {
                this.services.navigator.redirect(redirectUrl);
                await delay(Date.now(), 10000); // To keep the spinner running until the redirect actually happen.
            }
        })


        return resultPromise;
    }

    private get isRedirectInProgress(): boolean {
        return this._paymentStrategy.isRedirectInProgress;
    }

    private get _shouldFinalizePayment(): boolean {
        return this.booking.bookingStrategyAdapter.supportsPayment
            && this.isRedirectInProgress
            && this.booking.bookingStrategyAdapter.getFinalizePaymentRoute().equals(this.services.navigator.currentRoute);
    }

    private get _shouldTryFinalizePendingPayment(): boolean {
        return this.booking.bookingStrategyAdapter.supportsPayment
            && this.isRedirectInProgress
            && this.booking.bookingStrategyAdapter.getPaymentRoute().equals(this.services.navigator.currentRoute);
    }

    private async _finalizePayment(paymentStatusFromQueryParams: PaymentStatusQueryParamValuesEnum): Promise<void> {

        this._disposeActivateFromPaymentReaction();

        this._resolveRedirectPromise();

        const onPaymentCanceled = async (paymentStatusResponse: IPaymentStatusResponse) => {

            this.booking.bookingStrategyAdapter.getPaymentRoute().activate({
                allowBack: false
            });

            await this.onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Cancel,
                shouldRefreshBooking: false,
                apiErrorCode: paymentStatusResponse.apiErrorCode,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }

        const onPaymentFailed = async (paymentStatusResponse: IPaymentStatusResponse) => {
            this.booking.bookingStrategyAdapter.getPaymentRoute().activate({
                allowBack: false
            });
            await this.onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: false,
                apiErrorCode: paymentStatusResponse.apiErrorCode,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }

        const onPaymentFailedFatal = async (paymentStatusResponse: IPaymentStatusResponse) => {
            await this.onPayBookingFailed({
                status: PaymentTransactionStatusEnum.FatalError,
                shouldRefreshBooking: false,
                apiErrorCode: null,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }



        const paymentStatus = await this.services.loadingIndicator.execute({
            action: async () => {
                const paymentStatusObserver = new PaymentStatusObserver(this.services, this._paymentStrategy);
                let timeout: TimeSpan | undefined = undefined;
                //if the status from query params is success then we will wait until we get a finalized status
                //This is because when we get success callback the status in apcopay can be OK or PENDING. So we need to wait for a final state
                if(paymentStatusFromQueryParams !== PaymentStatusQueryParamValuesEnum.Success) {
                    timeout = TimeSpan.fromSeconds(3);
                }
                return await paymentStatusObserver.start(timeout);
            }
        });

        if(paymentStatus.status === PaymentTransactionStatusEnum.Completed) {
            await this._onPayBookingSuccess();
            return;
        }

        if(await this.booking.session.isExpired()) {
            return;
        }

        switch (paymentStatus.status) {

            case PaymentTransactionStatusEnum.Cancel:
                await onPaymentCanceled(paymentStatus);
                break;
            case PaymentTransactionStatusEnum.FatalError:
                await onPaymentFailedFatal(paymentStatus);
                break;
            default:
                if(paymentStatusFromQueryParams === PaymentStatusQueryParamValuesEnum.Canceled) {
                    await onPaymentCanceled(paymentStatus);
                } else {
                    await onPaymentFailed(paymentStatus);
                }

        }
    }

    private async _tryFinalizePendingPayment(): Promise<void> {

        //we end-up here when the user press back button from payment provider page
        //so in this case we try to get a final status for 3 seconds.
        const paymentStatus = await this.services.loadingIndicator.execute({
            action: async () => {
                const paymentStatusObserver = new PaymentStatusObserver(this.services, this._paymentStrategy);
                return await paymentStatusObserver.start(TimeSpan.fromSeconds(4));
            }
        });


        if(paymentStatus.status === PaymentTransactionStatusEnum.NotFinalized) {
            await this.loadPaymentMethods({
                showLoadingIndicator: true
            });
        } else if(paymentStatus.status === PaymentTransactionStatusEnum.Completed) {
            await this._onPayBookingSuccess();
        } else {

            if(!await this.booking.session.isExpired()) {

                await this.onPayBookingFailed({
                    status: paymentStatus.status,
                    shouldRefreshBooking: false,
                    apiErrorCode: paymentStatus.apiErrorCode,
                    pspErrorDetails: paymentStatus.pspErrorDetails
                });
            }


        }
    }
}


