import {AnalyticsBaseEvents} from "./analytics-base.events";
import {
    IAnalyticsEvent,
    IAnalyticsPaymentEcommerceModel,
    IAnalyticsPaymentEvents
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {BookingModel} from "../../../booking/models/booking.model";
import {BookingSessionStorageKeys} from "../../../booking/models/storage/booking-storage.interface";

export class AnalyticsPaymentsEvents extends AnalyticsBaseEvents implements IAnalyticsPaymentEvents {
    logAddPaymentInfo(booking: BookingModel): void {
        try {
            const data : IAnalyticsEvent<IAnalyticsPaymentEcommerceModel> = {
                event: 'add_payment_info',
                ecommerce: {
                    currency: booking.currency,
                    value: booking.balanceDue.amount,
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    coupon: booking.promoCode.code.value ?? "",
                    payment_type: this._getSelectedPaymentMethodsWithCategoryAsString(booking)!,
                    items: [
                        ...this._getBookingFaresAnalyticsItems(booking),
                        ...this._getAnalyticsSeatFeeItems(booking),
                        ...this._getAnalyticsSsrFeeItems(booking),
                    ]
                }
            }
            booking.storage.setJson(BookingSessionStorageKeys.analyticsPaymentDetails, data)
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logAddPaymentInfo failed', err);
        }
    }

    logPurchase(booking: BookingModel): void {
        try {
            // we get the payment info event and add some info to it to have the purchase event
            const paymentInfoEvent = booking.storage.getJson<IAnalyticsEvent<IAnalyticsPaymentEcommerceModel>>(BookingSessionStorageKeys.analyticsPaymentDetails)

            if(!paymentInfoEvent) {
                this.services.logger.warning("Add_payment_info event was not found in current session.")
                return;
            }

            const data: IAnalyticsEvent<IAnalyticsPaymentEcommerceModel> = {
                event: 'purchase',
                ecommerce: {
                    ...paymentInfoEvent.ecommerce,
                    tax: booking.bookingData.breakdown.journeyTotals.totalTax,
                    transaction_id: booking.recordLocator,
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logPurchase failed', err);
        }
    }

    logViewCart(booking: BookingModel): void {
        try {
            const data: IAnalyticsEvent = {
                event: 'view_cart',
                ecommerce: {
                    currency: booking.currency,
                    value: booking.balanceDue.amount,
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...this._getBookingFaresAnalyticsItems(booking),
                        ...this._getBookingLevelFeesAnalyticsPurchaseItems(booking),
                        ...this._getAnalyticsSeatFeeItems(booking),
                        ...this._getAnalyticsSsrFeeItems(booking),
                        ...this._getAnalyticsOtherFeeItems(booking),
                    ]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logViewCart', err);
        }
    }

    logAddRemarketingPurchase(booking: BookingModel): void {
        try {
            let flightValue = 0;
            const paymentInfoEvent = booking.storage.getJson<IAnalyticsEvent<IAnalyticsPaymentEcommerceModel>>(BookingSessionStorageKeys.analyticsPaymentDetails)
            if(paymentInfoEvent) {
              flightValue = paymentInfoEvent.ecommerce.value;
            }
            const data: unknown = {
                event: 'ads_remarketing_purchase',
                flight_originid: booking.departureJourney?.designator.origin.stationCode,
                flight_destid: booking.departureJourney?.designator.destination.stationCode,
                flight_pagetype: 'purchase',
                flight_startdate: booking.departureJourney?.analyticsFormattedDepartureDate,
                flight_enddate: booking.returnJourney?.analyticsFormattedDepartureDate,
                flight_totalvalue: flightValue,
                order_id: booking.recordLocator,
                currency: booking.currency,
                booked_fares: [booking.departureJourney?.selectedBundle?.bundleCode], //
                // net_fare_total: 199.35,
                p_id: '',
                c_id: '',
                // maturity_date: '2025-01-03'

            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logViewCart', err);
        }
    }

    private _getSelectedPaymentMethodsWithCategoryAsString (booking: BookingModel): string {
        const selectedMethod = booking.paymentHandler.getSelectedPaymentMethod();
        if(selectedMethod) {
            return `${selectedMethod.category}|${selectedMethod.code}`
        } else {
            return '';
        }
    }
}