import {PaymentMethodTabModel} from "../payment-method-tab.model";
import React from "react";
import {
    CreditCardPaymentMethodsTabContentComponent
} from "../../../../../../pages/booking-flow/payment/credit-card/credit-card-payment-methods-tab-content.component";
import {
    CreditCardPaymentMethodsTabHeaderComponent
} from "../../../../../../pages/booking-flow/payment/credit-card/credit-card-payment-methods-tab-header.component";
import {
    CreditCardPayButtonComponent
} from "../../../../../../pages/booking-flow/payment/credit-card/credit-card-pay-button.component";
import {PaymentMethodTabEnum} from "../payment-method-tab.enum";
import {BookingModel} from "../../../booking.model";
import {ICreditCardPaymentHandlerViewModel} from "./credit-card-payment-handler.view-model";
import {PaymentMethodCategoryEnum} from "../../methods/payment-method-category.enum";

export class CreditCardTabModel extends PaymentMethodTabModel implements ICreditCardPaymentHandlerViewModel {

    constructor(booking: BookingModel) {
        super(PaymentMethodTabEnum.CreditCards, booking);
    }

    async isAvailable(): Promise<boolean> {
        for(let pm of this.paymentHandler.creditCardPaymentMethods) {
            if(await pm.canMakePayments()) {
                return true;
            }
        }

        return false;
    }

    async startPayment(): Promise<void> {
        const selectedPaymentMethod = this.booking.paymentHandler.getSelectedPaymentMethod();
        if(selectedPaymentMethod && selectedPaymentMethod.category !== PaymentMethodCategoryEnum.CreditCard) {
            this.booking.paymentHandler.clearPaymentMethodSelection();
        }
        await this.booking.paymentHandler.startBookingPayment();
    }

    renderTabHeader(): React.ReactElement {
        return (
            <CreditCardPaymentMethodsTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <CreditCardPaymentMethodsTabContentComponent paymentMethods={this.paymentHandler.creditCardPaymentMethods}
                                                         paymentHandler={this.paymentHandler}/>
        )
    }

    renderPayButton(): React.ReactElement {
        return (
            <CreditCardPayButtonComponent paymentHandler={this}/>
        );
    }

}