import { ReactElement, JSXElementConstructor } from "react";
import { IPassengerSegmentBoardingPassViewModel } from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IMobileWalletStrategy} from "../mobile-wallet.strategy.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {
    AddToGoogleWalletButtonComponent
} from "../../../components/add-to-wallet-buttons/google/add-to-google-wallet-button.component";
import {Check} from "../../../types/type-checking";

export class GoogleWalletStrategy implements IMobileWalletStrategy {

    constructor(private readonly services: IServiceFactory) {
    }

    readonly providerName: string = 'Google Wallet';

    //Google doesn't really have a limit
    readonly maxBoardingPassesInBundle: number = 50;

    canAddBoardingPassToWallet(boardingPass: IPassengerSegmentBoardingPassViewModel): boolean {
        return this.services.device.isAndroid;
    }

    async addBoardingPassesToWallet(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._addBoardingPassesToWallet(bookingKey, boardingPasses);
            }
        })
    }
    renderAddToWalletButton(onClickHandler: () => Promise<void>): ReactElement<any, string | JSXElementConstructor<any>> {
        return (
            <AddToGoogleWalletButtonComponent onClick={onClickHandler}/>
        )
    }

    private async _addBoardingPassesToWallet(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {
         const result = await this.services.airlineWebapi.createGoogleBoardingPasses({
            bookingKey: bookingKey,
            passengers: boardingPasses.map(bp => {
                return {
                    passengerKey: bp.passengerKey,
                    segmentKey: bp.segmentKey,
                    isInfant: bp.isInfant,
                }
            }),
            currentLanguage: this.services.language.currentLanguage.toString(),
            urls: [
                {
                    url: this.services.configuration.data.websiteHomeUrl,
                    description: this.services.configuration.data.websiteHomeUrl,
                    id: "website-url",
                }
            ]
        })

        if(Check.isNullOrUndefined(result.error) && !Check.isEmpty(result.saveToWalletUrl)) {
            this._addToGoogleWallet(result.saveToWalletUrl)

            return;
        }
        this.services.logger.error(`Could not add to google wallet with ${result.error}`);
        this.services.alert.showError(this.services.language.translate("Could not add to wallet"));
    }

    private _addToGoogleWallet(walletUrl: string){
        // const walletUrl = `https://pay.google.com/gp/v/save/${jwt}`;
        window.open(walletUrl, '_blank');
    };
}