export enum PaymentTransactionStatusEnum
{
    NotFinalized = 0,
    Error = 1,
    Completed = 2,
    Cancel = 3,
    FatalError = 5,
    //Authorized = 6,
    PendingReview = 7
}

export function isPaymentSuccess(transactionStatus: PaymentTransactionStatusEnum) {
    return transactionStatus === PaymentTransactionStatusEnum.Completed
        || transactionStatus === PaymentTransactionStatusEnum.PendingReview;
}

export function isPaymentFailure(transactionStatus: PaymentTransactionStatusEnum): boolean {
    return transactionStatus === PaymentTransactionStatusEnum.Error
            || transactionStatus === PaymentTransactionStatusEnum.FatalError;
}