import {PaymentMethodTabModel} from "../payment-method-tab.model";
import React from "react";
import {PaypalPaymentMethodTabHeaderComponent} from "../../../../../../pages/booking-flow/payment/paypal/paypal-payment-method-tab-header.component";
import {PaypalPaymentMethodTabContentComponent} from "../../../../../../pages/booking-flow/payment/paypal/paypal-payment-method-tab-content.component";
import {PaypalButtonComponent} from "../../../../../../pages/booking-flow/payment/paypal/paypal-button.component";
import {PaymentMethodTabEnum} from "../payment-method-tab.enum";
import {IPaypalPaymentMethodViewModel} from "../../methods/paypal/paypal-payment-method.view-model.interface";
import {BookingModel} from "../../../booking.model";

export class PaypalTabModel extends PaymentMethodTabModel {

    constructor(booking: BookingModel) {
        super(PaymentMethodTabEnum.PayPal, booking);
    }

    get payPalMethod(): IPaypalPaymentMethodViewModel | null {
        return this.paymentHandler.payPalMethod;
    }

    async isAvailable(): Promise<boolean> {
        return Boolean(this.payPalMethod);
    }

    renderTabHeader(): React.ReactElement {
        return (
            <PaypalPaymentMethodTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <PaypalPaymentMethodTabContentComponent />
        )
    }

    renderPayButton(): React.ReactElement {
        if(!this.payPalMethod) {
            return (
                <></>
            );
        }
        return (
            <PaypalButtonComponent payPalMethod={this.payPalMethod}/>
        );
    }


}