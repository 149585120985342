import React from "react";
import {computePaymentMethodIconSize} from "./compute-payment-method-icon-size";
import {useContainerMediaQueriesChecks} from "../../hooks/use-container-media-queries-checks.hook";
import {observer} from "mobx-react";
import {
    IRenderPaymentMethodIconOptions
} from "../../services/booking/models/payment/methods/payment-method.view-model.interface";

export const LogoMaestroIcon: React.FC<IRenderPaymentMethodIconOptions> = observer((props) => {

    return (
        <svg {...computePaymentMethodIconSize(useContainerMediaQueriesChecks(), props)} viewBox="0 0 91 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_37341_7762)">
                <path
                    d="M83.1249 0H8.12588C3.9838 0 0.625977 3.35782 0.625977 7.4999V52.4993C0.625977 56.6414 3.9838 59.9992 8.12588 59.9992H83.1249C87.267 59.9992 90.6248 56.6414 90.6248 52.4993V7.4999C90.6248 3.35782 87.267 0 83.1249 0Z"
                    fill="#DFE3E8"/>
                <g clipPath="url(#clip1_37341_7762)">
                    <path
                        d="M77.7744 42.459V41.6572H77.5739L77.3401 42.1918L77.1062 41.6572H76.9057V42.459H77.0394V41.8577L77.2732 42.3922H77.4403L77.6741 41.8577V42.459H77.7744ZM76.438 42.459V41.7909H76.7053V41.6572H76.0371V41.7909H76.3044V42.459H76.438Z"
                        fill="#00A2E5"/>
                    <path d="M54.0214 45.9001H35.9473V13.4268H54.0214V45.9001Z" fill="#7375CF"/>
                    <path
                        d="M37.1161 29.6632C37.1161 23.0817 40.1897 17.2017 45.0005 13.4266C41.4926 10.6536 37.0493 9.0166 32.2384 9.0166C20.846 9.0166 11.5918 18.2708 11.5918 29.6632C11.5918 41.0556 20.846 50.3098 32.2384 50.3098C37.0493 50.3098 41.4926 48.6728 45.0005 45.8998C40.1897 42.1247 37.1161 36.2447 37.1161 29.6632Z"
                        fill="#EB001B"/>
                    <path
                        d="M78.4097 29.6632C78.4097 41.0556 69.1555 50.3098 57.7631 50.3098C52.9523 50.3098 48.5089 48.6728 45.001 45.8998C49.8118 42.1247 52.8854 36.2447 52.8854 29.6632C52.8854 23.0817 49.8118 17.2017 45.001 13.4266C48.5089 10.6536 52.9523 9.0166 57.7631 9.0166C69.1555 9.0166 78.4097 18.2708 78.4097 29.6632Z"
                        fill="#00A2E5"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_37341_7762">
                    <rect width="89.9988" height="59.9992" fill="white" transform="translate(0.625977)"/>
                </clipPath>
                <clipPath id="clip1_37341_7762">
                    <rect width="66.8175" height="51.984" fill="white" transform="translate(11.5908 9.01562)"/>
                </clipPath>
            </defs>
        </svg>

    )
})
