import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PersonNameFieldEditorComponent} from "../../../../components/forms/person/person-name-field-editor.component";
import {EmailFieldEditorComponent} from "../../../../components/forms/contact/email-field-editor.component";
import styled from "styled-components";
import {PhoneNumberInputComponent} from "../../../../components/forms/contact/phone-number-input.component";


const ContactFieldsContainerBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
    }
`

interface BookingContactFieldsComponentProps {
    isInHeadlessMode: boolean;
}
export const BookingContactFieldsComponent: React.FC<BookingContactFieldsComponentProps> = observer((props) => {
    const services = useServices();

    const fields = services.booking.current.contact?.fields;
    if(!fields) {
        return null;
    }

    const renderFirstAndLastName = () => {
        if(props.isInHeadlessMode) {
            return null;
        }

        return (
            <>
                <PersonNameFieldEditorComponent key={fields.firstName.fieldName}
                                                field={fields.firstName} className={'tst-contact-first-name'}/>
                <PersonNameFieldEditorComponent key={fields.lastName.fieldName}
                                                field={fields.lastName} className={'tst-contact-last-name'}/>
            </>
        )
    }

    return (
        <ContactFieldsContainerBox className={'tst-booking-contact-fields'}>
            {renderFirstAndLastName()}

            <EmailFieldEditorComponent key={fields.emailAddress.fieldName} field={fields.emailAddress} className={'tst-contact-email'}/>

            <PhoneNumberInputComponent key={fields.phoneNumber.fieldName}
                                       field={fields.phoneNumber}
                                       countryField={fields.countryCode}
                                       className={'tst-contact-phone'}/>
            {
                /*
                <CountryPickerComponent key={fields.countryCode.fieldName}
                                    field={fields.countryCode} className={'tst-contact-country'}/>
                */
            }


        </ContactFieldsContainerBox>
    );
});
