import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {BookingStepBase} from "./booking-step.base";
import {
    IOnBeforeActivationContext
} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";
import {PrePaymentOperations} from "./pre-payment-operations";


export abstract class FinalPriceBaseStep<TRoutes> extends BookingStepBase {
    protected abstract _goToPaymentStep(): Promise<void>;
    protected abstract get routes(): TRoutes;

    get title(): string {
        return this.services.language.translate('Price');
    }


    protected _shouldBeDisplayed(): boolean {
        return !this.booking.bookingStrategyAdapter.hideFinalPriceStep;
    }

    async validate(): Promise<ValidationResultEnum> {
        if(!this.booking.travelConditionsAccepted) {
            this.services.alert.showError(this.services.language.translate('You must accept the travel conditions'));
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async next(): Promise<void> {
        const booking = this.booking;

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }

        await waitForBookingMutations(this.booking);

        if(booking.balanceDue.amount > 0 || booking.voucher.isApplied) {
            await this._goToPaymentStep();
        } else {
            await booking.paymentHandler.startBookingPayment();
        }
    }



    protected async _onBeforeActivation(context: IOnBeforeActivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation && !this.booking.bookingStrategyAdapter.hideFinalPriceStep) {
            const operations = new PrePaymentOperations(this.booking, {loadPaymentMethodsAsync: true});
            await operations.executePrepaymentOperations();
        }
        return await super._onBeforeActivation(context);
    }
}
