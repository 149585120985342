import React from "react";
import {computePaymentMethodIconSize} from "./compute-payment-method-icon-size";
import {useContainerMediaQueriesChecks} from "../../hooks/use-container-media-queries-checks.hook";
import {observer} from "mobx-react";
import {
    IRenderPaymentMethodIconOptions
} from "../../services/booking/models/payment/methods/payment-method.view-model.interface";


export const LogoMastercardIcon: React.FC<IRenderPaymentMethodIconOptions> = observer((props) => {


    return (
        <svg {...computePaymentMethodIconSize(useContainerMediaQueriesChecks(), props)} viewBox="0 0 91 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_34977_124788)">
                <path
                    d="M82.8749 0H7.87588C3.7338 0 0.375977 3.35782 0.375977 7.4999V52.4993C0.375977 56.6414 3.7338 59.9992 7.87588 59.9992H82.8749C87.017 59.9992 90.3748 56.6414 90.3748 52.4993V7.4999C90.3748 3.35782 87.017 0 82.8749 0Z"
                    fill="#DFE3E8"/>
                <path d="M54.957 14.3633H35.8135V45.6379H54.957V14.3633Z" fill="#F26122"/>
                <path
                    d="M37.8765 29.9993C37.8449 23.9122 40.6097 18.1476 45.3764 14.362C37.2208 7.94823 25.5053 8.87958 18.4655 16.5013C11.4256 24.1231 11.4256 35.8755 18.4655 43.4972C25.5053 51.119 37.2208 52.0503 45.3764 45.6366C40.6097 41.8509 37.8449 36.0863 37.8765 29.9993Z"
                    fill="#EA1D25"/>
                <path
                    d="M75.6377 42.3185V41.6811H75.9189V41.5498H75.2627V41.6811H75.5252V42.3185H75.6377ZM76.9127 42.3185V41.5498H76.7252L76.5002 42.0935L76.2564 41.5498H76.0689V42.3185H76.2002V41.7373L76.4252 42.2435H76.5752L76.7814 41.7373V42.3185H76.9127Z"
                    fill="#F69E1E"/>
                <path
                    d="M77.5496 30C77.5447 37.6112 73.1975 44.5524 66.352 47.8794C59.5065 51.2064 51.3627 50.336 45.375 45.6373C54 38.846 55.4936 26.3518 48.7125 17.7189C47.7404 16.4688 46.6196 15.3418 45.375 14.3627C51.3627 9.66399 59.5065 8.79353 66.352 12.1205C73.1975 15.4476 77.5447 22.3888 77.5496 30Z"
                    fill="#F69E1E"/>
            </g>
            <defs>
                <clipPath id="clip0_34977_124788">
                    <rect width="89.9988" height="59.9992" fill="white" transform="translate(0.375977)"/>
                </clipPath>
            </defs>
        </svg>


    )
})
