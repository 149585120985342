import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {GooglePayMarkIcon} from "../../../../assets/svg/google-pay-mark.icon";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 90px;
`

export const GooglePayPaymentMethodTabHeaderComponent: React.FC = observer(() => {
    return (
        <HeaderBox>
            <GooglePayMarkIcon/>
        </HeaderBox>
    )
})