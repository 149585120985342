import {ParentRouteModel} from "../../models/parent-route.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import React from "react";
import {IManageMyBookingRoutes} from "./manage-my-booking.routes.interface";
import {RouteBaseModel} from "../../models/route-base.model";
import {ManageMyBookingDashboardPage} from "../../../../pages/manage-my-booking/dashboard/manage-my-booking-dashboard.page";
import {ManageMyBookingNewFlightsDatesSelectionPage} from "../../../../pages/manage-my-booking/change-flight/new-flights-dates-selection/manage-my-booking-new-flights-dates-selection.page";
import {ManageMyBookingSelectNewDepartingFlightPage} from "../../../../pages/manage-my-booking/change-flight/new-flights-selection/manage-my-booking-new-departing-flight-selection.page";
import {ManageMyBookingSelectNewReturnFlightPage} from "../../../../pages/manage-my-booking/change-flight/new-flights-selection/manage-my-booking-new-return-flight-selection.page";
import {ManageMyBookingSeatsSelectionPage} from "../../../../pages/manage-my-booking/change-flight/seats-selection/manage-my-booking-seats-selection.page";
import {ManageMyBookingUnresolvedServicesPage} from "../../../../pages/manage-my-booking/change-flight/unresolved-services/manage-my-booking-unresolved-services.page";
import {ManageMyBookingBagsSelectionPage} from "../../../../pages/manage-my-booking/change-flight/bags-selection/manage-my-booking-bags-selection.page";
import {ManageMyBookingExtrasSelectionPage} from "../../../../pages/manage-my-booking/change-flight/extras/manage-my-booking-extras.page";
import {ManageMyBookingFlightsToRefundSelectionPage} from "../../../../pages/manage-my-booking/refund-to-wallet/flights-to-refund-selection/manage-my-booking-flights-to-refund-selection.page";
import {PaymentPage} from "../../../../pages/booking-flow/payment/payment.page";
import {FinalPricePage} from "../../../../pages/booking-flow/final-price/final-price.page";
import {FinalizePaymentPage} from "../../../../pages/finalize-payment/finalize-payment.page";


export class ManageMyBookingRoutes extends ParentRouteModel implements IManageMyBookingRoutes {
    constructor(services: IServiceFactory) {
        super('/manage-my-booking', services);
    }

    dashboard = new ChildRouteModel('/dashboard', this,() => <ManageMyBookingDashboardPage/>);
    selectNewFlightsDates = new ChildRouteModel('/select-new-flights-dates', this,() => <ManageMyBookingNewFlightsDatesSelectionPage/>);
    selectNewDepartingFlight = new ChildRouteModel('/select-new-departing-flight', this,() => <ManageMyBookingSelectNewDepartingFlightPage/>);
    selectNewReturnFlight = new ChildRouteModel('/select-new-return-flight', this,() => <ManageMyBookingSelectNewReturnFlightPage/>);
    unresolvedServices = new ChildRouteModel('/unresolved-services', this,() => <ManageMyBookingUnresolvedServicesPage/>);
    selectSeats = new ChildRouteModel('/select-seats', this,() => <ManageMyBookingSeatsSelectionPage/>);
    bags = new ChildRouteModel('/bags', this,() => <ManageMyBookingBagsSelectionPage/>);
    extras = new ChildRouteModel('/extras', this,() => <ManageMyBookingExtrasSelectionPage/>);
    finalPrice = new ChildRouteModel('/final-price', this,() => <FinalPricePage/>);
    payment = new ChildRouteModel('/payment', this,() => <PaymentPage/>);
    selectFlightsToRefund = new ChildRouteModel('/select-flights-to-refund', this,() => <ManageMyBookingFlightsToRefundSelectionPage/>);
    finalizePayment = new ChildRouteModel('/finalize-payment', this, () => <FinalizePaymentPage/>);


    protected _getDefaultChildRoute(): RouteBaseModel {
        return this.dashboard;
    }
}
