import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const LogoApplePayIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1} sizeIsHeight {...props} viewBox="0 0 54.705 22" ratio={54.705/22}>
            <g fill={props.color ?? "currentColor"}>
                <path d="M209.78,299.84c-1.341,0-2.422.811-3.12.811-.732,0-1.712-.766-2.873-.766-2.208,0-4.45,1.837-4.45,5.272a10.977,10.977,0,0,0,1.859,5.892c.868,1.228,1.645,2.242,2.748,2.242s1.578-.721,2.941-.721,1.689.7,2.895.7,2.005-1.092,2.759-2.174a9.842,9.842,0,0,0,1.206-2.524,3.917,3.917,0,0,1-2.365-3.594,3.988,3.988,0,0,1,1.9-3.367A4.136,4.136,0,0,0,209.78,299.84Zm-.62-1.452a4.043,4.043,0,0,0,.924-2.479,1.628,1.628,0,0,0-.034-.349,3.96,3.96,0,0,0-3.571,3.739,1.7,1.7,0,0,0,.034.327,1.322,1.322,0,0,0,.237.022A3.5,3.5,0,0,0,209.16,298.388Zm9.012-1.555v16.254h2.907v-5.328H224.6a5.258,5.258,0,0,0,5.587-5.43,5.205,5.205,0,0,0-5.463-5.5Zm2.907,2.433h2.884c2.1,0,3.278,1.07,3.278,3.063,0,1.938-1.217,3.019-3.289,3.019h-2.873Zm14.566,11.828c-1.239,0-2.129-.62-2.129-1.656,0-1,.732-1.566,2.3-1.667l2.782-.181v.98A2.7,2.7,0,0,1,235.646,311.094Zm-.823,2.2a4.065,4.065,0,0,0,3.65-1.893h.192v1.689h2.692v-8.415c0-2.613-1.78-4.157-4.945-4.157-2.929,0-4.968,1.386-5.194,3.56h2.636c.26-.834,1.138-1.284,2.422-1.284,1.51,0,2.32.676,2.32,1.881v1.036l-3.177.192c-3,.181-4.674,1.464-4.674,3.7C230.746,311.849,232.446,313.291,234.823,313.291Zm9.643,4.269c2.76,0,4.078-1.014,5.126-4.078l4.45-12.718h-2.951l-2.873,9.62h-.192l-2.884-9.62h-3.064l4.405,12.381-.146.541a2.02,2.02,0,0,1-2.209,1.588,5.433,5.433,0,0,1-.765-.045v2.287C243.543,317.549,244.3,317.56,244.466,317.56Z" transform="translate(-199.337 -295.56)"/>
            </g>
        </SvgIcon>
    )
}
