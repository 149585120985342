import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {LogoApplePayTabContentIcon} from "../../../../assets/svg/logo-apple-pay-tab-content.icon";
import {useServices} from "../../../../hooks/use-services.hook";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const LogoContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap20};
    
`

const TabContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap40};
    padding: ${props => props.theme.spacing.spacing48};
    width: 100%;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
        padding: ${props => props.theme.spacing.spacing20};
    }
`

const NowAcceptingBox = styled.div`
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
`

const DescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    line-height: 1.3;
`

const FooterBox = styled.div`
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.body2};
`


export const ApplePayPaymentMethodTabContentComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <ContainerBox>
            <TabContentBox>
                <LogoContainerBox>
                    <div>
                        <LogoApplePayTabContentIcon/>
                    </div>
                    <NowAcceptingBox>
                        {services.language.translate('Now Accepting Apple Pay')}
                    </NowAcceptingBox>
                </LogoContainerBox>

                <DescriptionBox>
                    {services.language.translate('Use Face ID or Touch ID for instant, secure payments. No need to enter your card data. Apple Pay stores your details safely for every purchase.')}
                </DescriptionBox>
            </TabContentBox>
            <FooterBox>
                {services.language.translate('Click the Apple Pay button to continue')}
            </FooterBox>
        </ContainerBox>
    )
})