import {IMobileWalletService} from "./mobile-wallet.service.interface";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import { IPassengerSegmentBoardingPassViewModel } from "../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IMobileWalletStrategy} from "./mobile-wallet.strategy.interface";
import {AppleWalletStrategy} from "./strategies/apple-wallet.strategy";
import {NoMobileWalletStrategy} from "./strategies/no-mobile-wallet.strategy";
import React from "react";
import {GoogleWalletStrategy} from "./strategies/google-wallet.strategy";

export class MobileWalletService extends ServiceBase implements IMobileWalletService {
    constructor(services: IServiceFactory) {
        super(services)
        if(services.device.isIOS) {
            this._currentStrategy = new AppleWalletStrategy(services);
        } else if(services.device.isAndroid) {
            this._currentStrategy = new GoogleWalletStrategy(services);
        } else {
            this._currentStrategy = new NoMobileWalletStrategy();
        }
    }

    private readonly _currentStrategy: IMobileWalletStrategy;

    get maxBoardingPassesInBundle(): number {
        return this._currentStrategy.maxBoardingPassesInBundle;
    }
    get providerName(): string {
        return this._currentStrategy.providerName;
    }

    canAddBoardingPassToWallet(boardingPass: IPassengerSegmentBoardingPassViewModel): boolean {
        if(this.services.time.parseIsoDate(boardingPass.designator.departureDate).getTime() < this.services.time.currentDate.getTime()) {
            return false;
        }

        return this._currentStrategy.canAddBoardingPassToWallet(boardingPass);
    }

    async addBoardingPassesToWallet(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {

        boardingPasses = this.filterEligiblePasses(boardingPasses);
        if(boardingPasses.length === 0) {
            return;
        }

        await this._currentStrategy.addBoardingPassesToWallet(bookingKey, boardingPasses);
    }

    filterEligiblePasses(boardingPass: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[] {
        return boardingPass.filter(bp => this.canAddBoardingPassToWallet(bp));
    }

    renderAddToWalletButton(onClickHandler: () => Promise<void>): React.ReactElement {
        return this._currentStrategy.renderAddToWalletButton(onClickHandler)
    }
}