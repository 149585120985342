import React from "react";
import {observer} from "mobx-react";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {/*OnApproveData,*/ PayPalButtonStyle} from "@paypal/paypal-js/types/components/buttons";
import {
    IPaypalPaymentMethodViewModel
} from "../../../../services/booking/models/payment/methods/paypal/paypal-payment-method.view-model.interface";


export const PaypalButtonComponent: React.FC<{payPalMethod: IPaypalPaymentMethodViewModel}> = observer((props) => {

    const createOrderHandler = () => {
        return props.payPalMethod.startOrder();
    }

    const onApproveHandler = (/*data: OnApproveData*/) => {
        return props.payPalMethod.finalizeOrder();
    }

    const onCancelHandler = () => {
        //we don't wait for the Promise because we don't want the PayPal window to hang around until the promise is resolved
        props.payPalMethod.cancelOrder();
    }


    const style: PayPalButtonStyle = {
        shape: 'rect',
        layout: "horizontal",
        label: 'paypal',
        color: 'gold',
        disableMaxWidth: true,
        height: 54
    }

    return (
        <PayPalButtons style={style}
                       createOrder={createOrderHandler}
                       onApprove={onApproveHandler}
                       onCancel={onCancelHandler}
                       fundingSource={"paypal"} />
    );
})