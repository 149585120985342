import React from "react";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";

const GoogleWalletButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

interface AddToGoogleWalletButtonComponentProps {
    onClick: () => Promise<void>;
    className?: string;
}

export const AddToGoogleWalletButtonComponent: React.FC<AddToGoogleWalletButtonComponentProps> = (props) => {
    const services = useServices();

    return (
      <GoogleWalletButton onClick={props.onClick} className={props.className}>
          <img src={services.theme.currentTheme.assets.images.add_to_google_wallet_png.toString()} alt={"Add to GoogleWallet"} width={356} height={61} />
      </GoogleWalletButton>
    );
}