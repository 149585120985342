import {OperatingSystem} from "@capacitor/device/dist/esm/definitions";

export enum  DeviceType {
    Android = 1,
    iOS = 2,
    other = 3
}

export type OperatingSystemName = Exclude<OperatingSystem, 'unknown'> | 'other';

export type PlatformType = 'hybrid' | 'web';

export interface IDeviceService {
    getDeviceId(): Promise<string>;
    readonly deviceType: DeviceType;
    readonly isHybrid: boolean;
    readonly isAndroidMobileHybrid: boolean;
    readonly isIOS: boolean;
    readonly isAndroid: boolean;
    readonly isWeb: boolean;
    readonly isMobile: boolean;
    getOperatingSystemName(): Promise<OperatingSystemName>;
    getPlatformType(): PlatformType;
    copyToClipboard(text: string): Promise<void>;
    showCopyToClipboardToast: boolean;

}
