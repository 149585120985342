import React from "react";
import {observer} from "mobx-react";
import {PaymentCreditCardIcon} from "../../../../assets/svg/payment-credit-card.icon";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    padding: ${props => props.theme.spacing.spacing16};
`

interface CreditCardPaymentsMethodTabHeaderComponentProps {

}
export const CreditCardPaymentMethodsTabHeaderComponent: React.FC<CreditCardPaymentsMethodTabHeaderComponentProps> = observer(() => {
    const services = useServices();

    const screenMediaQuery = useContainerMediaQueriesChecks();
    const renderLabel = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <span>
               {services.language.translate('Credit Card')}
            </span>
        );
    }

    return (
        <ContainerBox>
            <PaymentCreditCardIcon />
            {renderLabel()}
        </ContainerBox>
    )
})