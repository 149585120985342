import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {GooglePayMarkIcon} from "../../../../assets/svg/google-pay-mark.icon";
import {useServices} from "../../../../hooks/use-services.hook";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap40};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        gap: ${props => props.theme.spacing.flexGap30};
    }
`

const TabContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap40};
    padding: 0 ${props => props.theme.spacing.spacing40};
    width: 100%;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
        gap: ${props => props.theme.spacing.flexGap30};
        padding: 0 ${props => props.theme.spacing.spacing16};
    }
`

const DescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    line-height: 1.3;
    text-align: center;
`

const FooterBox = styled.div`
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.body2};
`

export const GooglePayPaymentMethodTabContentComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <ContainerBox>
            <TabContentBox>
                <GooglePayMarkIcon/>
                <DescriptionBox>
                    {services.language.translate('Approve purchases using your existing Google account. Eliminate extra logins, trust in Google’s secure platform, and enjoy a speedy, one-step booking process.')}
                </DescriptionBox>
            </TabContentBox>
            <FooterBox>
                {services.language.translate('Click the Google Pay button to continue')}
            </FooterBox>
        </ContainerBox>

    )
})