import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {SwiperComponent} from "../../../../basic-components/swiper/swiper.component";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {SwiperRef, SwiperSlide} from "swiper/react";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {TabItemBox} from "../../../../components/tabs/tab.component";
import {SWIPER_SLIDE_CSS_CLASS} from "../../../../basic-components/swiper/swiper-consts";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";

const ContainerBox = styled.div`
    margin: 0 calc(-1 * ${props => props.theme.spacing.pageContentHorizontalPadding});
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    padding: ${props => props.theme.spacing.spacing16}
             ${props => props.theme.spacing.pageContentHorizontalPadding}
             0
             ${props => props.theme.spacing.pageContentHorizontalPadding};
`

const SwiperBox = styled(SwiperComponent)<{$slideMaxWidth: string}>`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: none; 
    & .${SWIPER_SLIDE_CSS_CLASS} {
        max-width: ${props => props.$slideMaxWidth};
    }
`


export const SeatMapSegmentsSwiperSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const swiperRef = useRef<SwiperRef | null>(null);
    const seatMapsEditor = services.booking.current.seatsMapsEditors;
    const seatMaps = seatMapsEditor.seatMaps;
    const currentSeatMap = seatMapsEditor.currentSeatMap;

    const currentSeatMapIndex = Math.max(0, seatMaps.findIndex(sm => sm.segmentKey === currentSeatMap?.segmentKey));

    useEffect(() => {
        const swiper = swiperRef.current;
        if(swiper) {
            swiper.swiper.slideTo(currentSeatMapIndex);
        }
    }, [currentSeatMapIndex]);

    const renderOneSlide = (seatMap: ISegmentSeatMapEditorViewModel) => {
        const onClickHandler = () => {
            let passengerToSelect = seatMap.firstPassengerSegmentWithoutSeat;
            if(!passengerToSelect) {
                passengerToSelect = seatMap.segment.passengers[0];
            }
            seatMapsEditor.setCurrentPassengerSegment(passengerToSelect);
            setTimeout(() => {
                if(passengerToSelect?.hasSeat) {
                    passengerToSelect.scrollToAssignedSeat();
                }

            }, 500);
        }
        const isSelected = seatMapsEditor.currentSeatMap?.segmentKey === seatMap.segmentKey;
        return (
            <SwiperSlide key={seatMap.segmentKey}>
                <TabItemBox $isSelected={isSelected} onClick={onClickHandler}>
                    <DesignatorComponent displayMode={DesignatorDisplayMode.stationsCodeOnly}
                                         designator={seatMap.segment.designator}/>
                </TabItemBox>
            </SwiperSlide>
        );
    }

    const isOnFirstSlide = seatMapsEditor.currentSeatMap?.segmentKey === seatMaps[0].segmentKey;
    let maxSlidesPerView = 3
    let slideMaxWidth = "172px";

    let offset = "30px";

    if(screenMediaQuery.xsAndBelow) {
        maxSlidesPerView = 2;
        offset = "40px";

        if(screenMediaQuery.xxs) {
            offset = "20px";
        }
    }


    let slidesPerView = Math.min(seatMaps.length, maxSlidesPerView);
    const percentage = 100 / maxSlidesPerView;
    if(slidesPerView === maxSlidesPerView && seatMaps.length > slidesPerView) {
        if(isOnFirstSlide) {
            slideMaxWidth = `calc(${percentage}% - ${offset})`;
        } else {
            slideMaxWidth = `${percentage}%`;
        }
    }

    const centeredSlides = !isOnFirstSlide && seatMaps.length > slidesPerView

    return (
        <ContainerBox>
            <SwiperBox ref={swiperRef}
                       $slideMaxWidth={slideMaxWidth}
                       slidesPerView={slidesPerView}
                       spaceBetween={4}
                       centeredSlides={centeredSlides}
                       initialSlide={currentSeatMapIndex}>
                {seatMaps.map(renderOneSlide)}
            </SwiperBox>
        </ContainerBox>

    )
})