import {NullableUndefinedString} from "../types/nullable-types";

export function parseCommaSeparatedValues<TResult>(values: NullableUndefinedString, mapper?: (value: string) => TResult): TResult[] {
    if(!mapper) {
        mapper = (value: string) => value as TResult;
    }
    return (values ?? '').split(',')
                 .filter(n => (n ?? '').trim().length > 0)
                 .map(mapper);

}